import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import { Auth } from '../firebase/config'
import { signInAnonymously, linkWithCredential } from 'firebase/auth'
import { useGetUserData } from '../hooks/useUser'
import axios from '../api/axios'
import { registerUser } from '../api'
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
// @types
import { ActionMap, AuthState, AuthUser } from '../types/authentication'
import { Loader } from '../components'
// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isVerified: false
}

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    isVerified: boolean
    user: AuthUser | null
  }
}

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>]

const reducer = (state: AuthState, action: FirebaseActions): AuthState => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, isVerified } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isVerified,
      user
    }
  }
  return state
}

const AuthContext = createContext<AuthState | any>(initialState)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [user, loading /*, error*/] = useAuthState(Auth)
  const [signInWithEmailAndPassword, , loginLoading, loginError] = useSignInWithEmailAndPassword(Auth)
  const { data, isLoading: loadingDB, isFetching } = useGetUserData(user?.uid)

  useEffect(() => {
    if (user?.uid && !loading) {
      user.getIdToken().then((token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        // log into portal db
        // if (user.email)
        registerUser(user.uid, user.email || null)
          .then((res) => {
            console.log('AuthContext useEffect user saved to db')
          })
          .catch((err) => {
            console.error('AuthContext useEffect user not saved to db', err)
          })
      })
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          isVerified: user.emailVerified,
          user: { ...user, ...data, email: user.email }
        }
      })
    } else {
      delete axios.defaults.headers.common['Authorization']
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          isVerified: false,
          user: null
        }
      })
    }
  }, [user, loading, data])

  const signInAnonymouslyUser = () => signInAnonymously(Auth)

  const linkWithCredentialUser = async (credential: any) => {
    if (Auth.currentUser) {
      return await linkWithCredential(Auth.currentUser, credential)
    }
    throw new Error('No current user to link credentials with')
  }

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(email, password)
  }

  const logout = () => Auth.signOut()

  if (loading || (loadingDB && isFetching)) return <Loader />

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        // user: user ? { ...user, ...data, email: user.email } : null,
        // dbUser: data,
        // loading: loading || (loadingDB && isFetching),
        // loadingDB: loadingDB && isFetching,
        // error,
        login,
        loginLoading,
        loginError,
        logout,
        signInAnonymously: signInAnonymouslyUser,
        linkWithCredential: linkWithCredentialUser
        // updateProfile: () => {},
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

// export { AuthContext, AuthProvider }
