import { FC } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'

interface ReadButtonProps {
  bid: string
  page: number
  readCount: number
  slot?: string
  onClick?: () => void
}

const ReadButton: FC<ReadButtonProps> = ({ bid, page, readCount, slot, onClick }) => {
  return (
    <IonButton
      slot={slot}
      size="small"
      // className="text-sm sm:text-lg w-[80px] sm:w-[110px] h-[35px]"
      className="text-xl"
      color="quaternary"
      shape="round"
      fill="solid"
      routerLink={`/story/${bid}/${page}/${readCount}/false`}
      onClick={onClick}
    >
      اقــرأ الآن
      <IonIcon icon={chevronBack} />
    </IonButton>
  )
}

export default ReadButton
