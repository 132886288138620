import { isPlatform } from '@ionic/react'
import { ScreenOrientation } from '@capacitor/screen-orientation'

const is_DESKTOP =
  isPlatform('desktop') && !isPlatform('mobile') && !isPlatform('mobileweb') && !('ontouchstart' in window)

export const getRightRouter = () => {
  console.log(
    'screen orientation: ',
    ScreenOrientation.orientation().then((res) => console.log(res))
  )
  console.log('is_DESKTOP: ', is_DESKTOP, isPlatform('desktop'), isPlatform('mobile'), isPlatform('mobileweb'))
  if (is_DESKTOP) {
    return '/d/home'
  } else {
    return '/tabs/library'
  }
}
