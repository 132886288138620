import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useIonToast } from '@ionic/react'
import { useHistory } from 'react-router'
import { newSubscription, updateUserSubscription } from '../api'
// import UserAPI from '../firebase/services/user'
import { Messages } from '../api/server-messages'
import { format } from 'date-fns'
import { getRightRouter } from '../utils/getRightRouter'

export const useNewInAppSubscription = (uid: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['user', uid],
    mutationFn: async (data: any) => {
      await updateUserSubscription(uid, data)
      queryClient.setQueryData(['user', uid], (old: any) => {
        return { ...old, ...data }
      })
    }
  })
}

export const useNewSubscription = (uid: string) => {
  const queryClient = useQueryClient()
  const [present] = useIonToast()
  const history = useHistory()

  return useMutation({
    mutationKey: ['user', uid],
    mutationFn: async (data: { UID: string; promoCode: string }) => {
      // send request to server to create new subscription
      const response = await newSubscription(data.UID, data.promoCode)
      console.log('useNewSubscription response', response)
      // map response to match user subscription schema
      const mappedResponse = {
        isSubscribed: true,
        expiryDate: format(new Date(response.validUntil), 'yyyy-MM-dd'),
        expiryTime: new Date(response.validUntil).getTime(),
        purchaseDate: format(new Date(), 'yyyy-MM-dd'),
        purchaseTime: new Date().getTime(),
        type: 'promo'
      }
      //REMOVEME: this code related to firestore
      // // update user subscription in Firestore
      // await UserAPI().updateSubscription(uid, {
      //   subscription: {
      //     ...mappedResponse
      //   }
      // })
      //REMOVEME: this code related to firestore
      await updateUserSubscription(uid, {
        subscription: {
          ...mappedResponse
        }
      })
      // update user subscription in cache
      queryClient.setQueryData(['user', uid], (old: any) => {
        const subscription = {
          ...old.subscription,
          ...mappedResponse
        }
        return { ...old, subscription }
      })
    },
    onError: (error: any) => {
      console.log('useNewSubscription error', error)
      // Handle error and display toast
      present({
        message: Messages[error] || Messages.promoError,
        duration: 4000,
        color: 'danger',
        cssClass: 'ion-text-center'
      })
    },
    onSuccess: () => {
      // Display successful toast
      present({
        message: Messages.promoSuccess,
        duration: 4000,
        color: 'success',
        cssClass: 'ion-text-center'
      })
      // Redirect to home page
      // history.replace('/tabs/library')
      history.replace(getRightRouter())
    }
  })
}
