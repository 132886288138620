import axios from 'axios'

const instance = axios.create({
  // baseURL: 'http://localhost:3000',
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  timeout: 60000,
  headers: { 'X-Custom-Header': 'foobar' }
})

instance.interceptors.request.use(
  (response) => response,
  (error) => {
    // Handle error globally
    const errorMessage = error.response
      ? error.response.data.message
      : 'Network error occurred. Please try again later.'
    console.error('Axios request error: ', error.response.data.message)
    return Promise.reject(errorMessage)
  }
)

export default instance
