import { createContext, useContext, useState, ReactNode } from 'react'

interface AlertContextProps {
  alert: boolean
  setAlert: (alert: boolean) => void
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined)

export const useAlert = () => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider')
  }
  return context
}

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<boolean>(false)

  const contextValue: AlertContextProps = {
    alert,
    setAlert: (alert) => {
      setAlert(alert)
    }
  }

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
}
