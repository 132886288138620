import { FC, useEffect, useState } from 'react'
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { Thumbnail, AddButton, ReadButton, DownloadButton, SubscribeButton } from '../../components'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useGetBook } from '../../hooks/useLibrary'
import { useGetUsersBooks } from '../../hooks/useUserQueries'
import { useGetUserData } from '../../hooks/useUser'
// types
import { BookWithStatistics } from '../../types/book'
// utils
import { getDownloadUrl } from '../../utils/getDownloadUrl'
import { formatLevel, fNumberOfPages, fNumberOfWords } from '../../utils/format'
import { checkFolderExistence } from '../../utils/checkFolderExistence'
//
import useModalState from '../../hooks/useState'

const Details: FC = () => {
  const { user } = useAuth()

  const { state, dispatch } = useModalState()

  const { data: userData } = useGetUserData(user.uid)
  const { data: userBooks, isLoading } = useGetUsersBooks(user.uid)
  const book = useGetBook(state.bid) as BookWithStatistics

  const [downlaoded, setDownloaded] = useState(false)
  const [inLibrary, setInLibrary] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const checkFolder = async () => {
      setLoading(true)
      const folderExists = await checkFolderExistence(state.bid)
      setLoading(false)
      setDownloaded(folderExists)
    }
    checkFolder()

    const checkInLibrary = () => {
      if (!userBooks) return
      const isInLibrary = userBooks.find((b) => b.folderName === state.bid)

      setInLibrary(!!isInLibrary)
    }
    checkInLibrary()
  }, [userBooks, state.bid])

  const handleClosing = () => {
    dispatch({
      type: 'HIDE',
      payload: ''
    })
  }

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      handleClosing()
    })
  })

  interface Props {
    uid: string
    downloadUrl: string
  }
  const RenderButton = ({ downloadUrl, uid }: Props) => {
    if (loading || isLoading) {
      return (
        <IonButton color="quaternary">
          <IonSpinner name="dots" />
        </IonButton>
      )
    }
    if (book?.free || inLibrary || (userData?.subscription !== undefined && userData?.subscription.isSubscribed)) {
      if (!inLibrary) {
        return <AddButton book={book} />
      } else {
        if (downlaoded) {
          return <ReadButton bid={state.bid} page={1} readCount={0} onClick={handleClosing} />
        } else {
          return <DownloadButton bid={state.bid} downloadUrl={downloadUrl} uid={uid} />
        }
      }
    }
    return <SubscribeButton onClick={handleClosing} />
  }

  return (
    book && (
      <IonModal isOpen={state.show} onDidDismiss={handleClosing}>
        <IonHeader translucent className="ion-no-border">
          <IonToolbar color={'quaternary'} className="rounded-b-3xl">
            <IonTitle>حول القصة</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleClosing}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="book-details-content">
          <IonCard className="container mx-auto shadow-2xl rounded-xl lg:p-10">
            <IonCardContent>
              <IonGrid className="w-full xl:p-10">
                <IonRow className="lg:mb-10">
                  <IonCol sizeXs="12" sizeLg="4" sizeXl="3">
                    <Thumbnail
                      isCover
                      withShadow
                      bid={book.folderName}
                      downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                    />
                  </IonCol>
                  <IonCol sizeXs="12" sizeLg="8" sizeXl="7">
                    <IonGrid className="h-full ion-justify-content-between ion-align-items-center">
                      <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol sizeXs="12" sizeLg="6">
                          <h1 className="font-bold leading-10 text-center text-black lg:text-right ion-text-wrap ion-padding font-29LTKaff-Medium">
                            {book?.title}
                          </h1>
                        </IonCol>
                        <IonCol sizeXs="12" sizeLg="6">
                          <div className="w-3/5 mx-auto text-center">
                            {RenderButton({
                              downloadUrl: getDownloadUrl(book.folderName, book.level, `${book.folderName}.zip`),
                              uid: user.uid
                            })}
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonGrid className="lg:mt-10">
                          <IonRow className="ion-justify-content-between ion-align-items-center">
                            <IonCol>
                              <IonItem>
                                <IonImg src="./assets/icon/ic_book_level.svg" slot="start" className="w-12 h-12" />
                                <IonLabel className="w-full">
                                  <h2 className="font-bold">
                                    المستوى:&nbsp;
                                    <span className="inline text-lg font-normal text-quaternary-main">
                                      {formatLevel(book?.level)}
                                    </span>
                                  </h2>
                                </IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol>
                              <IonItem>
                                <IonImg src="./assets/icon/ic_book_words.svg" slot="start" className="w-12 h-12" />
                                <IonLabel className="w-full">
                                  <h2>
                                    عدد الكلمات:&nbsp;
                                    <span className="inline text-lg font-normal text-quaternary-main">
                                      {fNumberOfWords(book?.words)}
                                    </span>
                                  </h2>
                                </IonLabel>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                          <IonRow className="ion-justify-content-between ion-align-items-center">
                            <IonCol>
                              <IonItem>
                                <IonImg src="./assets/icon/ic_book_pages.svg" slot="start" className="w-12 h-12" />
                                <IonLabel className="w-full">
                                  <h2>
                                    عدد الصفحات:&nbsp;
                                    <span className="inline text-lg font-normal text-quaternary-main">
                                      {fNumberOfPages(book?.pages)}
                                    </span>
                                  </h2>
                                </IonLabel>
                              </IonItem>
                            </IonCol>
                            <IonCol>
                              <IonItem>
                                <IonImg src="./assets/icon/ic_book_publisher.svg" slot="start" className="w-12 h-12" />
                                <IonLabel className="w-full">
                                  <h2>
                                    الناشر:&nbsp;
                                    <span className="text-quaternary-main">
                                      {book?.publisher.map((r, index) => (
                                        <span key={index} className="inline text-lg font-normal">
                                          {r}
                                          {index !== book.publisher.length - 1 && ', '}
                                        </span>
                                      ))}
                                    </span>
                                  </h2>
                                </IonLabel>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                          <IonRow className="ion-justify-content-between ion-align-items-center">
                            {book.arabi21 !== '0' && (
                              <IonCol>
                                <IonItem>
                                  <IonImg src="./assets/icon/ic_arabi_21.png" slot="start" className="w-12 h-12" />
                                  <IonLabel className="w-full">
                                    <h2>
                                      عربي 21:&nbsp;
                                      <span className="text-quaternary-main text-lg font-normal">
                                        المستوى {book.arabi21}
                                      </span>
                                    </h2>
                                  </IonLabel>
                                </IonItem>
                              </IonCol>
                            )}
                            <IonCol>
                              <IonItem>
                                <IonImg src="./assets/icon/ic_book_category.png" slot="start" className="w-12 h-12" />
                                <IonLabel className="w-full">
                                  <h2>
                                    التصنيف:&nbsp;
                                    <span className="text-quaternary-main text-lg font-normal">{book.category}</span>
                                  </h2>
                                </IonLabel>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
                <IonList>
                  <IonItem>
                    <IonLabel className="ion-text-wrap">
                      <h1 className="text-quaternary-main">حول القصة</h1>
                      <p className="font-29LTKaff">
                        <span
                          className="text-lg text-black lg:text-2xl"
                          style={{
                            lineHeight: '45px'
                          }}
                        >
                          {book?.description}
                        </span>
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h1 className="text-quaternary-main">القيم المستفادة</h1>
                      {book?.keywords.map((keyword, index) => (
                        <p key={index} className="font-29LTKaff">
                          <span className="text-lg text-black lg:text-2xl">
                            {keyword}
                            {index !== book.keywords.length - 1 && ', '}
                          </span>
                        </p>
                      ))}
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
    )
  )
}

export default Details
