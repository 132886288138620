import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
// import UserAPI from '../firebase/services/user_DEPRECATED'
import { BookWithStatistics } from '../types/book'
import { checkFolderExistence } from '../utils/checkFolderExistence'
import { userAddBook, updateUserBook, getUserBooks, userDeleteBook } from '../api'
import { formatDateForMySQL } from '../utils/format'
// export const useGetUserBook = (uid: string, bid: string) => {
//   const queryClient = useQueryClient()
//   const book = find(queryClient.getQueryData(['user', 'books', uid]), (b: BookWithStatistics) => b.folderName === bid)
//   return book as BookWithStatistics
// }

export const useUserAddBook = (uid: string, book: BookWithStatistics) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'books', book.folderName],
    mutationFn: async () => {
      const added_ms = await userAddBook(uid, book)
      // add book to cache
      queryClient.setQueryData(['user', 'books', uid], (old: any) => {
        return [...old, added_ms]
      })
      // queryClient.invalidateQueries(['user', 'books'])
      return queryClient.setQueryData(['user', 'books', book.folderName], added_ms)
    }
  })
}

export const useGetUsersBooks = (uid: string) => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['user', 'books', uid],
    queryFn: async () => {
      const books_ms = await getUserBooks(uid)
      const booksWithDownloadedProperty = await Promise.all(
        books_ms.map(async (book: any) => {
          const folder = await checkFolderExistence(book.folderName)
          book.downloaded = folder
          return book
        })
      )
      queryClient.setQueryData(['books'], (old: any) => {
        // merge two arrays of books by using reduce
        const newBooks = booksWithDownloadedProperty.reduce((acc: any, book: any) => {
          const found = acc.find((b: any) => b.folderName === book.folderName)
          if (!found) {
            return [...acc, book]
          }
          return acc
        }, old)
        return newBooks
      })
      booksWithDownloadedProperty.map((book) => {
        queryClient.setQueryData(['books', book.folderName], book)
        return queryClient.setQueryData(['user', 'books', book.folderName], book)
      })
      return books_ms
    },
    enabled: !!uid
  })
}

export const useUserEditBookStats = (uid: string, bid: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'books', bid],
    mutationFn: async (stats: any) => {
      try {
        if (stats.updatedAt) {
          stats.updatedAt = formatDateForMySQL(new Date(stats.updatedAt))
        }

        if (stats.downloaded === undefined) {
          await updateUserBook(uid, bid, stats)
        }
        queryClient.setQueryData(['user', 'books', uid], (old: any) => {
          return old.map((book: any) => {
            if (book.folderName === bid) {
              return { ...book, ...stats }
            }
            return book
          })
        })
        queryClient.setQueryData(['user', 'books', bid], (old: any) => {
          return { ...old, ...stats }
        })
      } catch (error) {
        console.log('FIRESTORE ERROR FROM HOOKS: ', error)
      }
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(['user', 'books'])
    }
  })
}

type Params = {
  uid: string
  bid: string
}
export const useUserDeleteBook = (params: Params) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'books', params.bid],
    mutationFn: async () => {
      try {
        const deleted_ms = await userDeleteBook(params.uid, params.bid)
        // delete book from cache
        queryClient.setQueryData(['user', 'books', params.uid], (old: any) => {
          return old.filter((book: any) => book.folderName !== params.bid)
        })
        queryClient.removeQueries(['user', 'books', params.bid])
        return deleted_ms
      } catch (error) {
        console.log('DELETE FROM FIRESTORE ERROR: ', error)
      }
    }
  })
  // return useMutation({
  //   mutationKey: ['user', 'books', params.bid],
  //   mutationFn: async () => {
  //     const deleted = await UserAPI().userDeleteBook(params.uid, params.bid)
  //     // delete book from cache
  //     queryClient.setQueryData(['user', 'books', params.uid], (old: any) => {
  //       return old.filter((book: any) => book.folderName !== params.bid)
  //     })
  //     queryClient.removeQueries(['user', 'books', params.bid])
  //     return deleted
  //   }
  // })
}
