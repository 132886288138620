import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
  IonText,
  IonList,
  IonSpinner,
  useIonToast
} from '@ionic/react'
import { twMerge } from 'tailwind-merge'
import emailjs from '@emailjs/browser'

const schema = yup.object().shape({
  name: yup.string().required('الاسم مطلوب'),
  from_email: yup.string().email('البريد الالكتروني غير صحيح').required('البريد الالكتروني مطلوب'),
  subject: yup.string().required('الموضوع مطلوب'),
  message: yup.string().required('الرسالة مطلوبة')
})

// interface ContactUsFormProps {
//   onSubmit: (data: any) => void
// }

const ContactUsForm: FC = () => {
  const {
    handleSubmit,
    trigger,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const [sending, setSending] = useState(false)

  const [present] = useIonToast()

  const onSubmitHandler = async (data: any, e: any) => {
    e.preventDefault()
    setSending(true)
    try {
      await emailjs.sendForm('service_k34hezs', 'template_mg56ic1', e.target, 'CvIV5gI0xqueJBMvs')

      present({
        message: 'تم إرسال رسالتك بنجاح، سنقوم بالرد عليك في أقرب وقت ممكن',
        duration: 3000,
        color: 'success',
        position: 'top'
      })
    } catch (error) {
      console.log('EmailJS Error: ', error)
      present({
        message: 'حدث خطأ ما',
        duration: 3000,
        color: 'danger'
      })
    } finally {
      setSending(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <IonGrid className="max-w-lg mt-10">
        <IonRow>
          <IonCol>
            <IonText color="dark">
              إن كان لديك أي استفسار أو مشكلة تواجهك في استخدام التطبيق، يمكنك التواصل معنا عبر تعبئة النموذج أدناه
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList>
              <IonInput
                type="text"
                fill="outline"
                autocomplete="on"
                shape="round"
                color={'tertiary'}
                label=""
                placeholder="الاسم"
                onIonChange={() => {
                  trigger('name')
                }}
                clearInput
                className={twMerge(
                  'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                  errors.name && 'mb-0'
                )}
                {...register('name')}
              />
              {errors.name && (
                <IonText color="danger">
                  <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.name.message}</p>
                </IonText>
              )}
              <IonInput
                type="email"
                fill="outline"
                autocomplete="on"
                shape="round"
                color={'tertiary'}
                label=""
                placeholder="البريد الإلكتروني"
                onIonChange={() => {
                  trigger('from_email')
                }}
                clearInput
                className={twMerge(
                  'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                  errors.from_email && 'mb-0'
                )}
                {...register('from_email')}
              />
              {errors.from_email && (
                <IonText color="danger">
                  <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.from_email.message}</p>
                </IonText>
              )}
              <IonInput
                type="text"
                fill="outline"
                autocomplete="on"
                shape="round"
                color={'tertiary'}
                label=""
                placeholder="الموضوع"
                onIonChange={() => {
                  trigger('subject')
                }}
                clearInput
                className={twMerge(
                  'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                  errors.subject && 'mb-0'
                )}
                {...register('subject')}
              />
              {errors.subject && (
                <IonText color="danger">
                  <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.subject.message}</p>
                </IonText>
              )}
              <IonTextarea
                fill="outline"
                shape="round"
                autoGrow={true}
                color={'tertiary'}
                label=""
                placeholder="الرسالة"
                onIonChange={() => {
                  trigger('message')
                }}
                className={twMerge(
                  'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                  errors.message && 'mb-0'
                )}
                {...register('message')}
              />
              {errors.message && (
                <IonText color="danger">
                  <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.message.message}</p>
                </IonText>
              )}
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton type="submit" shape="round">
              {sending ? <IonSpinner /> : <IonText className="text-2xl p-4">إرسال</IonText>}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  )
}

export default ContactUsForm
