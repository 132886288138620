import { FC, useCallback, useEffect, useState } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonChip,
  IonCol,
  IonDatetime,
  IonGrid,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText
} from '@ionic/react'
import { DatetimeHighlight } from '@ionic/core'
import useModalState from '../hooks/useState'
import { useAuth } from '../contexts/AuthContext'
import { useUserPlans, useUpdateTaskProgress } from '../hooks/useTasks'
import { NotFound } from '../components'
import { Plan, Task } from '../types/plan'
import { twMerge } from 'tailwind-merge'

const monthNames = [
  'يناير',
  'فبراير',
  'مارس',
  'أبريل',
  'مايو',
  'يونيو',
  'يوليو',
  'أغسطس',
  'سبتمبر',
  'أكتوبر',
  'نوفمبر',
  'ديسمبر'
]

const TasksView: FC = () => {
  const { user } = useAuth()
  const { data: plans, isLoading: loadingPlans, isError: errorPlans } = useUserPlans(user?.uid)
  const { mutate: updateTaskProgress } = useUpdateTaskProgress(user?.uid)

  const { dispatch } = useModalState()

  const [planTasks, setPlanTasks] = useState<Task[]>([])
  const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0])
  const [activePlan, setActivePlan] = useState<Plan | null>(null)
  const [highlightedDates, setHighlightedDates] = useState<DatetimeHighlight[]>([])

  const handleHighlightDates = useCallback(() => {
    const highlighted = [{ date: '', backgroundColor: '' }] as DatetimeHighlight[]
    plans?.map((p: Plan) => {
      return p.tasks.map((t: Task) => {
        return highlighted.push({ date: t.task_date.split('T')[0], backgroundColor: '#ffb199' })
      })
    })
    setHighlightedDates(highlighted)
  }, [plans])

  useEffect(() => {
    if (plans && plans.length > 0) {
      console.log('plans', plans)
      const sortedPlans = plans.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
      const initialPlan = sortedPlans[0]
      setActivePlan(initialPlan)
      // setPlanTasks(initialPlan.tasks || [])
      handleHighlightDates()
    }
    console.log('component mounted')
  }, [plans, handleHighlightDates])

  useEffect(() => {
    if (activePlan) {
      const firstDay = activePlan.start_date.split('T')[0]
      setDate(firstDay)
      const tasksForFirstDay = activePlan.tasks.filter((t: Task) => t.task_date.split('T')[0] === firstDay)
      setPlanTasks(tasksForFirstDay)
    }
  }, [activePlan])

  const getPickerValue = (e: CustomEvent) => {
    const date = (e.detail.value as string).split('T')[0]
    setDate(date)
    formatDate(date)
    if (activePlan) {
      const tasksForSelectedDate = activePlan.tasks.filter((t: Task) => t.task_date.split('T')[0] === date)
      setPlanTasks(tasksForSelectedDate)
    }
  }

  const formatDate = (date: string) => {
    const d = new Date(date.split('T')[0])
    return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear()
  }

  const handleIonSelectChange = useCallback(
    (e: CustomEvent) => {
      const selectedPlanId = e.detail.value
      const newActivePlan = plans?.find((plan) => plan.plan_id === selectedPlanId) || null
      setActivePlan(newActivePlan)
      handleHighlightDates()
      // setPlanTasks(newActivePlan?.tasks || [])
    },
    [plans, handleHighlightDates]
  )

  const handleUpdateTask = useCallback(
    (task_id: number, done: number) => {
      updateTaskProgress({ task_id, data: { done: done === 0 ? 1 : 0 } })
    },
    [updateTaskProgress]
  )

  const NoPlans = () => {
    return <NotFound title="لائحة المهام خالية" subtitle="لا يوجد مهام" img="/assets/icon/tasks.png" />
  }

  const LoadingPlans = () => {
    return (
      <div className="flex flex-col justify-center items-center min-h-[300px] mx-2">
        <IonCard className="flex flex-col items-center justify-between w-full max-w-xl py-6 rounded-xl gap-y-4">
          <IonCardTitle>جاري التحميل...</IonCardTitle>
        </IonCard>
      </div>
    )
  }

  const RenderPlans = () => {
    if (loadingPlans || errorPlans) return <LoadingPlans />
    else if (!plans || plans.length === 0) return <NoPlans />
    else
      return (
        <IonGrid className="w-full mx-auto">
          <IonRow>
            <IonCol sizeXs="12" sizeXl="4">
              <DatePicker />
            </IonCol>
            <IonCol sizeXs="12" sizeXl="8">
              <PlansCard />
            </IonCol>
          </IonRow>
        </IonGrid>
      )
  }

  const DatePicker = () => {
    return (
      <IonDatetime
        value={date}
        locale="ar-AE"
        color={'tertiary'}
        presentation="date"
        onIonChange={getPickerValue}
        highlightedDates={highlightedDates}
        className="mx-auto shadow-xl rounded-2xl"
      />
    )
  }

  const PlansCard = () => {
    return (
      <IonCard className="shadow-xl rounded-xl ion-no-margin">
        <IonCardTitle className="py-4 font-bold text-center text-tertiary-main">مهامي الأسبوعية</IonCardTitle>
        <IonCardContent>
          <PlanCardHeader />
          <IonCard className="p-4 rounded-xl bg-tertiary-lighter">
            {/* <IonRow> */}
            {/* <IonCol> */}
            {/* PLAN GOALS */}
            <IonItem>
              <IonImg className="w-8 h-8" slot="start" src="/assets/icon/ic_plan_goals_f.svg" />
              <IonLabel className="font-bold">الأهداف</IonLabel>
            </IonItem>
            <IonList className="ion-no-padding">
              {activePlan?.goals.map((goal: string, index: number) => (
                <IonItem key={`goal-${index}`} className="w-full pr-4 bg-tertiary-lighter">
                  <div className="bg-tertiary-main p-1 mx-1 rounded-full"></div>
                  <IonText>{goal}</IonText>
                </IonItem>
              ))}
            </IonList>
            <IonItemDivider color={'inherit'} />

            {/* PLAN BOOKS */}
            <IonItem>
              <IonImg className="w-8 h-8" slot="start" src="/assets/icon/ic_plan_books_f.svg" />
              <IonLabel className="font-bold">الكتب</IonLabel>
            </IonItem>
            <div className="flex gap-2 m-4">
              {activePlan?.books.bid.map((bid: string, index: number) => (
                <IonChip key={`book-${bid}-${index}`} onClick={() => dispatch({ type: 'SHOW', payload: bid })}>
                  <IonText className="ion-no-padding text-center">{activePlan?.books.title[index]}</IonText>
                </IonChip>
              ))}
            </div>
            <IonItemDivider color={'inherit'} />
            {/* </IonCol> */}

            {/* <IonCol> */}
            {/* PLAN TASKS */}
            <IonItem>
              <IonImg className="w-8 h-8" slot="start" src="/assets/icon/ic_plan_tasks_f.svg" />
              <IonLabel className="font-bold">المهام</IonLabel>
            </IonItem>
            <IonList className="ion-no-padding">
              {planTasks.length === 0 ? (
                <IonItem className="w-full pr-4 bg-tertiary-lighter">
                  <IonText>لا توجد مهام لهذا اليوم</IonText>
                </IonItem>
              ) : (
                planTasks.map((task: Task, index: number) => (
                  <IonItem
                    key={`task-${task.task_id}-${task.task_description}-${index}`}
                    className="bg-tertiary-lighter"
                    onClick={(e: any) => {
                      e.preventDefault() // Prevent default behavior
                      e.stopPropagation() // Prevent event bubbling
                      handleUpdateTask(task.task_id, task.done || 0)
                    }}
                  >
                    <IonCheckbox slot="start" checked={!!task.done} />
                    <span className={twMerge(task.done ? 'line-through' : 'no-underline')}>
                      {task.task_description}
                    </span>
                  </IonItem>
                ))
              )}
            </IonList>
            <IonItemDivider color={'inherit'} />
            {/* </IonCol> */}
            {/* </IonRow> */}
          </IonCard>
        </IonCardContent>
      </IonCard>
    )
  }

  console.log('highlightedDates', highlightedDates)

  const PlanCardHeader = () => {
    return (
      <IonCardHeader>
        {/* <IonItem> */}
        <div className="flex justify-between items-start md:items-center flex-col md:flex-row ">
          <IonSelect
            slot="start"
            color="tertiary"
            interface="popover"
            placeholder={activePlan?.title}
            onIonChange={handleIonSelectChange}
          >
            {plans?.map((plan: Plan, index: number) => (
              <IonSelectOption key={`plan-${plan.plan_id}-${index}`} value={plan.plan_id}>
                {plan.title}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonLabel slot="end">
            من:<span className="font-bold"> {formatDate(activePlan?.start_date || '')}</span>
          </IonLabel>
        </div>
        {/* </IonItem> */}
        <div className="flex justify-between items-start md:items-center flex-col md:flex-row ">
          <IonLabel slot="start">المعلم: {activePlan?.teacher}</IonLabel>
          <IonLabel slot="end">
            إلى: <span className="font-bold">{formatDate(activePlan?.end_date || '')}</span>
          </IonLabel>
        </div>
      </IonCardHeader>
    )
  }

  return RenderPlans()
}

export default TasksView
