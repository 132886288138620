import { IonImg, IonText } from '@ionic/react'

const Slide1: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-primary-main relative pb-10">
      <IonImg src="/assets/illustrations/intro/intro1.jpg" className="max-w-[200px] md:max-w-[250px] " />
      <IonText className="text-center text-2xl font-bold block mt-2 w-3/4 sm:w-1/2 leading-10">
        مرحباً بكم في تطبيقنا، تمتع بالعديد من القصص المليئة بالمتعة والإثارة
      </IonText>
    </div>
  )
}

export default Slide1
