import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Network } from '@capacitor/network'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { IonImg, IonThumbnail, IonSkeletonText } from '@ionic/react'
// hooks
import { useImage } from '../../contexts/ImageContext'
import { useNetwork } from '../../contexts/NetworkContext'
import { OfflineAlert } from '../../components'
import { useGetBook } from '../../hooks/useLibrary'

interface ThumbnailProps {
  bid: string
  downloadUrl: string
  isCover?: boolean
  withShadow?: boolean
  onClick?: () => void
  classname?: string
  recordedOverlay?: boolean
}

function Thumbnail({
  bid,
  downloadUrl,
  isCover,
  withShadow,
  onClick,
  classname,
  recordedOverlay = true
}: ThumbnailProps) {
  const { connected } = useNetwork()

  const { image, setImage, imgLoading, setImgLoading } = useImage()

  const book = useGetBook(bid)

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!image[bid]) getURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid])

  const base64FromBlob = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject('method did not return a string')
        }
      }
      reader.readAsDataURL(blob)
    })
  }

  const getURL = () => {
    setImgLoading(bid, true)
    // get download url from firebase storage if network is available and image is not cached in filesystem else get from filesystem
    Network.getStatus().then((status) => {
      if (status.connected) {
        // before getting image from firebase storage check if image is cached in filesystem
        Filesystem.readFile({
          path: bid,
          directory: Directory.Cache
        })
          // if image is cached in filesystem then get image from cache otherwise get image from firebase storage
          .then((result) => {
            const webviewPath = `data:image/jpeg;base64,${result.data}`
            // get image path to display
            setImage(bid, webviewPath)
          })
          .catch(async (error) => {
            setLoading(false)
            setImgLoading(bid, false)
            setImage(bid, downloadUrl)
            const blob = await fetch(downloadUrl).then((r) => r.blob())
            const base64Data = await base64FromBlob(blob)
            Filesystem.writeFile({
              path: bid,
              data: base64Data,
              directory: Directory.Cache
            })
              .then((result) => {
                // console.log('result', result)
              })
              .catch((error) => {
                console.log('Thumbnail error 1', error)
              })
          })
          .finally(() => {
            setImgLoading(bid, false)
          })
      } else {
        Filesystem.readFile({
          path: bid,
          directory: Directory.Cache
        })
          .then((result) => {
            const webviewPath = `data:image/jpeg;base64,${result.data}`
            // get image path to display
            setImage(bid, webviewPath)
          })
          .catch((error) => {
            console.log('Thumbnail error 3', error, bid)
          })
          .finally(() => {
            setImgLoading(bid, false)
          })
      }
    })
  }

  if (!bid) return null

  return (
    <div
      className={twMerge(
        'rounded-2xl bg-[#edeff3] sm:my-4 relative mx-auto',
        isCover ? 'w-[200px] h-[290px] sm:w-[250px] sm:h-[350px]' : 'w-[140px] h-[225px] sm:w-[200px] sm:h-[300px]',
        withShadow && 'shadow-xl',
        !imgLoading[bid] && !loading ? classname : ''
      )}
    >
      {(imgLoading[bid] || loading) && (
        <IonThumbnail className="w-full h-full rounded-2xl">
          <IonSkeletonText animated style={{ '--border-radius': '1rem' }} />
        </IonThumbnail>
      )}
      <IonImg
        id={bid}
        src={connected || image[bid] ? image[bid] || 'assets/cover.jpg' : 'assets/cover.jpg'}
        alt="book cover"
        className={twMerge(
          'thumb object-cover shadow-lg rounded-2xl transition-opacity duration-500',
          imgLoading[bid] || loading ? 'opacity-0 invisible' : 'opacity-100',
          isCover
            ? 'w-[200px] h-[290px] sm:w-[250px] sm:h-[350px]'
            : 'w-[140px] h-[225px] sm:w-[200px] sm:h-[300px] hover:cursor-pointer',
          classname
        )}
        onIonImgWillLoad={() => {
          setLoading(true)
        }}
        onIonImgDidLoad={() => {
          setLoading(false)
        }}
        onIonError={(error) => {
          // show error image
          console.log('image error', downloadUrl, error)
          // set src to default image
          document.getElementById(bid)?.setAttribute('src', 'assets/cover.jpg')
        }}
        onClick={onClick}
      />
      {book?.audible && recordedOverlay ? (
        <img
          src="/assets/icon/ic_book_recorded.svg"
          alt="book recorded"
          className="absolute top-2 left-2 w-8 h-8 sm:w-10 sm:h-10"
        />
      ) : null}
      <OfflineAlert />
    </div>
  )
}

export default Thumbnail
