import { IonImg, IonText } from '@ionic/react'

const Slide3: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-quaternary-main relative pb-10">
      <IonImg src="/assets/illustrations/intro/intro2.png" className="w-[250px] md:w-[300px]" />
      <IonText className="text-center text-2xl font-bold block mt-2 w-3/4 sm:w-1/2 leading-10">
        قصص مختارة بعناية تربوية للقراء ما دون سن 15
      </IonText>
    </div>
  )
}

export default Slide3
