import { FC, useRef } from 'react'
import { IonSkeletonText, IonThumbnail, IonicSlides, useIonToast } from '@ionic/react'
// components
import { Thumbnail } from '../../components'
// siwper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useGetUserData } from '../../hooks/useUser'
import { useGetFreeBooks } from '../../hooks/useLibrary'
import useModalState from '../../hooks/useState'
import { getDownloadUrl } from '../../utils/getDownloadUrl'

/**
 * Renders a component that displays a list of free books for new and not subscribed users.
 *
 * @return {JSX.Element} The rendered component.
 */
const FreeBooks: FC = () => {
  const { user } = useAuth()
  const { data: userData } = useGetUserData(user?.uid)
  const { data: freeBooks, error: freeBooksError, isLoading: freeBooksIsLoading } = useGetFreeBooks()

  const { dispatch } = useModalState()

  const [presentToast] = useIonToast()

  // const [slidesPerView, setSlidesPerView] = useState(1)
  const containerRef = useRef<HTMLDivElement | null>(null)

  // const calculateSlidesPerView = () => {
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.clientWidth
  //     const slideWidth = 200 // Adjust based on your slide width
  //     const newSlidesPerView = Math.floor(containerWidth / slideWidth)
  //     setSlidesPerView(newSlidesPerView > 0 ? newSlidesPerView : 1)
  //   }
  // }

  // useEffect(() => {
  //   calculateSlidesPerView() // Initial calculation
  //   window.addEventListener('resize', calculateSlidesPerView)
  //   return () => {
  //     window.removeEventListener('resize', calculateSlidesPerView)
  //   }
  // }, [])

  if (freeBooksError) {
    console.log('error', freeBooksError)
    presentToast({
      message: freeBooksError as string,
      duration: 3000,
      color: 'danger',
      cssClass: 'text-center'
    })
  }

  // commented out for now
  const options = {
    slidesPerView: 3,
    spaceBetween: 10,
    // centeredSlides: true,
    grabCursor: true,
    // slidesOffsetBefore: 0,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    }
  }

  if (userData?.subscription?.isSubscribed) return null

  return (
    <div className="mt-8 w-full overflow-hidden" ref={containerRef}>
      <h3>قصص مجانيَّة</h3>
      {freeBooksIsLoading ? (
        <div className="flex flex-row gap-x-2">
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className="flex flex-col justify-between items-center w-full">
              <IonThumbnail className="w-[140px] h-[225px] sm:w-[200px] sm:h-[300px]">
                <IonSkeletonText animated />
              </IonThumbnail>
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          dir="rtl"
          navigation
          autoplay={{ delay: 5000 }}
          {...options}
          // slidesPerView={slidesPerView}
          modules={[Autoplay, IonicSlides]}
        >
          {freeBooks?.map((book, idx) => (
            <SwiperSlide key={`${book.title}__${idx}`} className="flex flex-col pb-8">
              {/* <Link to={`/book/${book.folderName}`}> */}
              <Thumbnail
                withShadow
                bid={book.folderName}
                downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                onClick={() => {
                  dispatch({ type: 'SHOW', payload: book.folderName })
                }}
              />
              {/* </Link> */}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default FreeBooks
