import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
// import UserAPI from '../firebase/services/user'
// import { User } from '../types/user'
import { Network } from '@capacitor/network'
import { isUserSubscribed } from '../pages/Subscription/Subscription'
import { isPlatform } from '@ionic/react'
// APIs
import { getUserData, updateUserData, updateUserSubscription } from '../api'
import { useNetwork } from '../contexts/NetworkContext'

export const useGetUserData = (uid: any) => {
  const { connected } = useNetwork()

  return useQuery({
    queryKey: ['user', uid],
    queryFn: async () => {
      const user = await getUserData(uid)
      if (Number.isNaN(user.statistics.favoriteLevel) || user.statistics.favoriteLevel === undefined) {
        user.statistics.favoriteLevel = 4
      }
      if (!user) {
        return {
          uid,
          email: '',
          avatar: '',
          name: '',
          subscription: {
            isSubscribed: false,
            expiryTime: 0,
            type: 'default',
            userID: uid
          },
          statistics: {
            levelTrack: 0,
            favoriteLevel: 4
          }
        }
      }
      const setUserSubscription = async (ret: any) => {
        if (ret.subscription.isSubscribed || user.subscription.isSubscribed) {
          console.log('user is subscribed from store')
          user.subscription = {
            ...user.subscription,
            ...ret.subscription
          }
          await updateUserSubscription(uid, {
            subscription: {
              ...user.subscription
            }
          })
        }
      }
      if (user.subscription !== undefined && user.subscription.isSubscribed) {
        const { isSubscribed, type, expiryTime } = user.subscription
        console.log('user is subscribed:', isSubscribed)
        const networkStatus = await Network.getStatus()
        const hybrid = (isPlatform('ios') && type === 'playStore') || (isPlatform('android') && type === 'appStore')
        if (type === 'promo' || hybrid || !networkStatus.connected) {
          const nowTime = new Date().getTime()
          if (expiryTime < nowTime) {
            user.subscription = {
              ...user.subscription,
              isSubscribed: false
            }
            await updateUserSubscription(uid, {
              subscription: {
                ...user.subscription
              }
            })
          }
        } else if (!isPlatform('desktop')) {
          isUserSubscribed(user).then((ret: any) => {
            setUserSubscription(ret)
          })
        }
      }
      return user
    },
    // onError: (error: any) => {
    //   console.log('useGetUserData error', JSON.stringify(error))
    // },
    enabled: !!uid && !!connected
  })
}

export const useEditUserData = (uid: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user'],
    mutationFn: async (data: any) => {
      try {
        if (!uid) return
        const updated = await updateUserData(uid, data)
        queryClient.setQueryData(['user', uid], (prev: any) => {
          return { ...prev, ...data }
        })
        return updated
      } catch (error) {
        console.log('useEditUserData error', error)
      }
    }
  })
}
