import { IonImg, IonText } from '@ionic/react'

const Slide2: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-tertiary-main relative pb-10">
      <IonImg src="/assets/illustrations/intro/intro3.png" className="max-w-[300px]" />
      <IonText className="text-center text-2xl font-bold block mt-2 w-3/4 sm:w-1/2 leading-10">
        التطبيق متوفر على جميع الأجهزة،
        <br /> ابدأ القراءة أينما كنت واستمع لأجمل القصص
      </IonText>
    </div>
  )
}

export default Slide2
