import { FC, useState } from 'react'
import { IonButton, IonIcon, IonText, useIonRouter } from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useGetUserData } from '../../hooks/useUser'
import { navigateToSubscribe } from '../../utils/subscriptionPath'
import { SignInModal } from '..'

const SubscriptionCTA: FC = () => {
  const { user } = useAuth()
  const router = useIonRouter()
  const { data: userData, isLoading, error } = useGetUserData(user.uid)
  const [isAnonymous, setAnonymous] = useState(false)

  if (isLoading) return null

  if (userData?.subscription?.isSubscribed) return null

  if (error) {
    console.log('Error in User Data', error)
    return null
  }

  const handleClick = () => {
    if (user?.isAnonymous) {
      console.log('User is anonymous')
      setAnonymous(true)
      return
    } else {
      router.push(navigateToSubscribe())
    }
  }

  return (
    <>
      <IonButton
        className="max-w-md text-lg font-bold mx-auto mb-8 ion-margin ion-padding-horizontal"
        color="light"
        expand="block"
        onClick={handleClick}
      >
        <IonText className="w-full text-right">اشترك الآن مع قصصي !</IonText>
        <IonIcon icon={chevronBack} slot="end" />
      </IonButton>
      <SignInModal
        isOpen={isAnonymous}
        message="لقراءة القصّة وإضافتها إلى مكتبتك، يجب عليك إنشاء حساب والاشتراك"
        onDismiss={() => {
          setAnonymous(false)
        }}
      />
    </>
  )
}

export default SubscriptionCTA
