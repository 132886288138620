import { createContext, ReactNode, useContext, useState, FC } from 'react'

interface DownloadContextProps {
  progress: Record<string, number>
  inProgress: Record<string, boolean>
  unzipping: Record<string, boolean>
  setProgress: (bid: string, progress: number) => void
  setInProgress: (bid: string, inProgress: boolean) => void
  setUnzipping: (bid: string, unzipping: boolean) => void
}

const DownloadContext = createContext<DownloadContextProps | undefined>(undefined)

export const useDownload = () => {
  const context = useContext(DownloadContext)
  if (context === undefined) {
    throw new Error('useDownload must be used within a DownloadProvider')
  }
  return context
}

export const DownloadProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [progress, setProgress] = useState<Record<string, number>>({})
  const [inProgress, setInProgress] = useState<Record<string, boolean>>({})
  const [unzipping, setUnzipping] = useState<Record<string, boolean>>({})

  const contextValue: DownloadContextProps = {
    progress,
    inProgress,
    unzipping,
    setProgress: (bid, newProgress) => {
      setProgress((prev) => ({ ...prev, [bid]: newProgress }))
    },
    setInProgress: (bid, newInProgress) => {
      setInProgress((prev) => ({ ...prev, [bid]: newInProgress }))
    },
    setUnzipping: (bid, newUnzipping) => {
      setUnzipping((prev) => ({ ...prev, [bid]: newUnzipping }))
    }
  }

  return <DownloadContext.Provider value={contextValue}>{children}</DownloadContext.Provider>
}
