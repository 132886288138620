import { isPlatform } from '@ionic/react'
import { Preferences } from '@capacitor/preferences'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'

export const useFirstTime = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['firstTime'],
    mutationFn: async (bool: boolean) => {
      queryClient.setQueryData(['firstTime'], bool)
    }
  })

  return useQuery({
    queryKey: ['firstTime'],
    queryFn: async () => {
      let firstTime
      if (isPlatform('hybrid')) {
        const pref = await Preferences.get({ key: 'firstTime' })
        firstTime = pref.value === null ? true : false
      } else {
        const val = localStorage.getItem('firstTime')
        val ? (firstTime = val === 'true' ? true : false) : (firstTime = true)
      }
      if (firstTime) {
        mutate(true)
        return true
      } else {
        mutate(false)
        return false
      }
    },
    initialData: () => {
      let firstTime
      if (isPlatform('hybrid')) {
        Preferences.get({ key: 'firstTime' }).then((res) => {
          firstTime = res.value === null ? true : false
          // console.log('Pref res: ', JSON.stringify(res))
        })
      } else {
        const val = localStorage.getItem('firstTime')
        val ? (firstTime = val === 'true' ? true : false) : (firstTime = true)
      }
      if (firstTime) {
        return true
      } else {
        return false
      }
    }
  })
}

export const useSetFirstTime = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['firstTime'],
    mutationFn: async (bool: boolean) => {
      if (isPlatform('hybrid')) {
        await Preferences.set({ key: 'firstTime', value: bool.toString() })
      } else {
        localStorage.setItem('firstTime', bool.toString())
      }
      queryClient.setQueryData(['firstTime'], bool)
    }
  })
}

export const useLoggedIn = () => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['loggedIn'],
    queryFn: async () => {
      let loggedIn
      if (isPlatform('hybrid')) {
        const pref = await Preferences.get({ key: 'loggedIn' })
        loggedIn = pref.value === null ? false : true
      } else {
        const val = localStorage.getItem('loggedIn')
        val ? (loggedIn = val === 'true' ? true : false) : (loggedIn = false)
      }
      if (loggedIn) {
        // mutate(true)
        queryClient.setQueryData(['loggedIn'], true)
        return true
      } else {
        queryClient.setQueryData(['loggedIn'], false)
        // mutate(false)
        return false
      }
    },
    initialData: () => {
      let loggedIn
      if (isPlatform('hybrid')) {
        Preferences.get({ key: 'loggedIn' }).then((res) => {
          // console.log('Pref res: ', JSON.stringify(res))
          loggedIn = res.value === null ? false : true
        })
      } else {
        const val = localStorage.getItem('loggedIn')
        val ? (loggedIn = val === 'true' ? true : false) : (loggedIn = false)
      }
      if (loggedIn) {
        return true
      } else {
        return false
      }
    }
  })
}

export const useSetLoggedIn = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['loggedIn'],
    mutationFn: async (bool: boolean) => {
      if (isPlatform('hybrid')) {
        await Preferences.set({ key: 'loggedIn', value: bool.toString() })
      } else {
        localStorage.setItem('loggedIn', bool.toString())
      }
      queryClient.setQueryData(['loggedIn'], bool)
    }
  })
}
