import { createContext, useState, useContext, ReactNode, useEffect } from 'react'

type SidebarContextType = {
  isOpen: boolean
  toggle: () => void
  setIsOpen: (isOpen: boolean) => void
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined)

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const storedState = localStorage.getItem('sidebar-open')
    if (storedState) setIsOpen(JSON.parse(storedState))
  }, [])

  const toggle = () => {
    setIsOpen((prev) => {
      const newState = !prev
      localStorage.setItem('sidebar-open', JSON.stringify(newState))
      return newState
    })
  }

  return <SidebarContext.Provider value={{ isOpen, toggle, setIsOpen }}>{children}</SidebarContext.Provider>
}

export const useSidebar = (): SidebarContextType => {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider')
  }
  return context
}

export default SidebarContext
