import { FC, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Redirect } from 'react-router-dom'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonInput,
  IonItem,
  IonCheckbox,
  useIonToast,
  IonSpinner,
  IonFooter
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { SocialAuth } from '../../components'
// forms
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
// firebase hooks
import { useAuth } from '../../contexts/AuthContext'
import { Auth } from '../../firebase/config'
import { Errors } from '../../firebase/error-codes'
import { EmailAuthProvider } from 'firebase/auth'
import { useCreateUserWithEmailAndPassword, useSendEmailVerification } from 'react-firebase-hooks/auth'
import { useSetFirstTime, useSetLoggedIn } from '../../hooks/useIntro'
import { useEditUserData } from '../../hooks/useUser'
//
import { navigateToSubscribe } from '../../utils/subscriptionPath'
import { isPlatform } from '@ionic/react'

import { getRightRouter } from '../../utils/getRightRouter'

interface RegisterForm {
  email: string
  password: string
  confirmPassword: string
  acceptTerms: boolean
}

const formSchema = yup.object().shape({
  email: yup.string().email('البريد الإلكتروني غير صحيح').required('البريد الإلكتروني مطلوب'),
  password: yup.string().required('كلمة المرور مطلوبة').min(6, 'كلمة المرور أقل من 6 حروف'),
  confirmPassword: yup
    .string()
    .required('تأكيد كلمة المرور مطلوبة')
    .oneOf([yup.ref('password')], 'كلمة المرور غير متطابقة'),
  acceptTerms: yup.boolean().oneOf([true], 'يجب الموافقة على الشروط والأحكام')
})

type Props = RouteComponentProps

const Register: FC<Props> = (props) => {
  const history = useHistory()

  const { currentUser } = Auth
  const { linkWithCredential, user } = useAuth()

  const { mutate: setLoggedIn } = useSetLoggedIn()
  const { mutate: setFirstTime } = useSetFirstTime()
  const { mutate: editUserData } = useEditUserData(user?.uid)

  const [linking, setLinking] = useState<boolean>(false)

  const [checked, setChecked] = useState(false)
  const [errMessage, setErrMessage] = useState<string>('')

  const isAnonymous = props.match.path === '/register/anonymous'

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm<RegisterForm>({
    mode: 'all',
    resolver: yupResolver(formSchema as any),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: checked
    }
  })

  const [createUserWithEmailAndPassword, , loading, error] = useCreateUserWithEmailAndPassword(Auth)

  const [sendEmailVerification, emailVerificationLoading, emailVerificationError] = useSendEmailVerification(Auth)

  const [presentToast] = useIonToast()

  const ISDESKTOP = isPlatform('desktop')

  const onSubmit = (data: RegisterForm) => {
    setErrMessage('')
    if (isAnonymous) {
      setLinking(true)
      const credential = EmailAuthProvider.credential(data.email, data.password)
      linkWithCredential(credential)
        .then((res: any) => {
          setLinking(false)
          presentToast({
            message: 'تم إنشاء الحساب بنجاح',
            duration: 3000,
            color: 'success',
            cssClass: 'text-center'
          })
          editUserData({ isAnonymous: false, email: data.email })
          history.replace(navigateToSubscribe())
        })
        .catch((err: any) => {
          console.error('link account error', err)
          setLinking(false)
          if (err.code !== errMessage) {
            setErrMessage(err.code)
            presentToast({
              message: Errors[err.code],
              duration: 3000,
              color: 'danger',
              cssClass: 'text-center'
            })
          }
        })
    } else {
      createUserWithEmailAndPassword(data.email, data.password).then((res) => {
        if (res?.user) {
          setLoggedIn(true)
          setFirstTime(false)
          sendEmailVerification()
          console.log('email sent')
          presentToast({
            message: 'تم إنشاء الحساب بنجاح، تم إرسال رسالة التحقق إلى بريدك الإلكتروني',
            duration: 3000,
            color: 'success',
            cssClass: 'text-center'
          })
        }
      })
    }
  }

  if (error?.code) {
    console.error('Register Error:', error)
    if (error.code !== errMessage) {
      setErrMessage(error.code)
      presentToast({
        message: Errors[error.code],
        duration: 3000,
        color: 'danger',
        cssClass: 'text-center'
      })
    }
  }

  if (emailVerificationError?.message) {
    console.error(emailVerificationError)
  }

  if (user && !currentUser?.isAnonymous) {
    // return <Redirect to="/tabs" />
    return <Redirect to={getRightRouter()} />
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar color="primary">
          <IonTitle>إنشاء حساب جديد</IonTitle>
          <IonButtons slot="end">
            <IonBackButton icon={arrowBack} defaultHref="/" text="" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="relative">
        {/* <IonHeader collapse="condense">
          <IonToolbar className="text-center">
            <IonTitle size="large" color={'tertiary'} className="text-center leading-loose mt-4">
              إنشاء حساب جديد
            </IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonGrid
          className={twMerge(
            'ion-padding ion-justify-content-center ion-align-items-center flex flex-col justify-center items-center w-full max-w-lg py-10',
            ISDESKTOP && 'bg-gray-50 rounded-2xl shadow-lg mt-10 bg-gradient-to-t from-primary-lighter to-gray-50 p-8'
          )}
        >
          <IonRow>
            <IonCol size="12" sizeMd="12" sizeLg="12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <IonInput
                  type="email"
                  fill="outline"
                  autocomplete="on"
                  shape="round"
                  color={'tertiary'}
                  label=""
                  placeholder="البريد الإلكتروني"
                  onIonChange={() => {
                    trigger('email')
                  }}
                  clearInput
                  className={twMerge(
                    'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                    errors.email && 'mb-0'
                  )}
                  {...register('email')}
                />
                {errors.email && (
                  <IonText color="danger">
                    <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.email.message}</p>
                  </IonText>
                )}
                <IonInput
                  type="password"
                  label=""
                  placeholder="كلمة المرور"
                  shape="round"
                  color={'tertiary'}
                  fill="outline"
                  onIonChange={() => {
                    trigger('password')
                  }}
                  clearInput
                  className={twMerge(
                    'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                    errors.password && 'mb-0'
                  )}
                  {...register('password')}
                />
                {errors.password && (
                  <IonText color="danger">
                    <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.password.message}</p>
                  </IonText>
                )}
                <IonInput
                  type="password"
                  label=""
                  placeholder="تأكيد كلمة المرور"
                  shape="round"
                  color={'tertiary'}
                  fill="outline"
                  onIonChange={() => {
                    trigger('confirmPassword')
                  }}
                  clearInput
                  className={twMerge(
                    'foucs:outline-none focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none ion-margin-bottom',
                    errors.confirmPassword && 'mb-0'
                  )}
                  {...register('confirmPassword')}
                />
                {errors.confirmPassword && (
                  <IonText color="danger">
                    <p className="ion-padding-start mt-1 mb-4">*&nbsp;{errors.confirmPassword.message}</p>
                  </IonText>
                )}
                <IonItem>
                  <IonCheckbox
                    slot="start"
                    aria-label="accept Terms"
                    className="ion-margin-end"
                    value={checked}
                    onIonChange={(v: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
                      trigger('acceptTerms')
                      setChecked(v.target.checked)
                    }}
                    {...register('acceptTerms')}
                  />
                  <IonText>
                    <p>
                      أوافق على جميع الشروط الواردة في{' '}
                      <Link
                        to={{ pathname: 'https://www.digital-future.ca/privacy_policy/ios/Qissasi-Terms.html' }}
                        target="_blank"
                        className="underline font-bold"
                      >
                        الشروط والأحكام
                      </Link>{' '}
                      و
                      <Link
                        to={{ pathname: 'https://www.digital-future.ca/privacy_policy/ios/Qissasi-Privacy.html' }}
                        target="_blank"
                        className="underline font-bold"
                      >
                        {' '}
                        سياسة الخصوصية
                      </Link>
                    </p>
                  </IonText>
                </IonItem>
                {errors.acceptTerms && (
                  <IonText color="danger">
                    <p className="ion-padding-start m-0">*&nbsp;{errors.acceptTerms.message}</p>
                  </IonText>
                )}
                <IonButton
                  type="submit"
                  expand="block"
                  shape="round"
                  size="large"
                  color="primary"
                  // disabled={!isValid}
                  className="ion-margin-top"
                >
                  {loading || emailVerificationLoading || linking ? (
                    <IonSpinner name="dots" className="ion-margin-start" />
                  ) : (
                    <span>إنشاء حساب</span>
                  )}
                </IonButton>

                <IonRow className="ion-margin-top">
                  <IonCol size="12" sizeMd="12" sizeLg="12">
                    <IonText className="text-center block" style={{ color: '#371945' }}>
                      أو التسجيل بواسطة
                    </IonText>
                  </IonCol>
                </IonRow>
              </form>
            </IonCol>
          </IonRow>
          <SocialAuth AnonymousSignIn={isAnonymous} />
          {!isAnonymous && <hr className="w-1/2 bg-meduim-main mx-auto" />}
          {!isAnonymous && (
            <IonRow className="ion-text-center ion-margin-top">
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonText className="ion-text-center"> لديك حساب سابق؟</IonText>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="12">
                <IonButton routerLink={isAnonymous ? '/login/anonymous' : '/login'} color="quaternary">
                  تسجيل الدخول
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          {/* <IonRow className="ion-text-center text-secondary-main self-end mt-8">
            <IonCol size="12" sizeMd="12" sizeLg="12">
              <IonText className="ion-text-center font-bold" style={{ color: '#afc1c4' }}>
                جميع الحقوق محفوظة لشركة المستقبل الرقمي وتخضع لجدول التعليمات والصلاحيات.
              </IonText>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar color={'light'}>
          <IonRow className="ion-text-center">
            <IonCol size="12">
              <IonText className="ion-text-center">
                جميع الحقوق محفوظة لشركة المستقبل الرقمي وتخضع لجدول التعليمات والصلاحيات.
              </IonText>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default Register
