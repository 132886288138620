import axios from './axios'
import { Messages } from './server-messages'
import { BookWithStatistics } from '../types/book'
import { Book } from '../types/book'
import { User } from '../types/user'
import { Network } from '@capacitor/network'
import {
  saveDataToCache,
  getCachedData,
  saveUserBookUpdatesToCache,
  saveDeletedBooksToCache,
  checkForCachedUpdates,
  clearUserCachedData
} from '../components/Cache/CacheData'
/*
 * This is the entry point for all API calls.
 * It is a wrapper around axios, which is a wrapper around fetch.
 */

// ----- Subscriptions API calls (for the Subscriptions page) -----

/** New subscription
 * @param {string} uid - User ID
 * @param {string} promoCode - Promo code
 * @returns {Promise} - Promise object represents the response data
 * @throws {string} - Error message
 */
export const newSubscription = async (uid: string, promoCode: string): Promise<any> => {
  const response = await axios.post('/promo/newSubscription', { uid, promoCode })
  const { data } = response
  const date = new Date()
  if (data.validUntil < date.toISOString().slice(0, 10)) throw Messages['Promo code is expired']
  if (data.result && data.status === 'success') return data.result
  throw data.result
}

// -----------------------------##########----------------------------- //

// ----- User API calls -----

/** Register new user
 * @param {string} uid - User ID
 * @param {string} email - User email
 * @param {string} name - User display name (optional)
 * @param {string} avatar - User image URL (optional)
 * @returns {Promise} - Promise object represents the response data
 * @throws {string} - Error message
 * @todo - Add more fields
 */
export const registerUser = async (uid: string, email: string | null): Promise<any> => {
  // send request to server
  const response = await axios.post('/user/regOrLogin', { uid, email })
  const { data } = response
  if (data.result && data.status === 'success') return data.result
  throw data.result
}

/** Delete User Account
 * @param {string} UID - User ID
 * @returns {Promise} - Promise object represents the response data
 * @throws {string} - Error message
 */
export const deleteUserAccount = async (UID: string): Promise<any> => {
  const response = await axios.delete(`/user/${UID}`)
  const { data } = response
  if (data.result && data.status === 'success') {
    clearUserCachedData(UID)
    return data.result
  }
  throw data.result
}

type UpdatedUser = {
  name: string
  avatar: string
  gender: string
}

/** @description update user's info
 * @param {string} uid
 * @param {UpdatedUser} data
 * @returns {Promise<any>}
 * @example
 * updateInfo('123456', { name: 'Student', avatar: 'https://i.pravatar.cc/300' })
 */
export const updateInfo = async (uid: string, data: UpdatedUser) => {
  console.log('updateInfo', uid, data)
  const res = await axios.put(`/user/${uid}`, data)

  if (res.data.result && res.data.status === 'success') {
    console.log('updateInfo res.data.result', res.data.result)
    return res.data.result
  }
  throw new Error(res.data.result)
}
///////////////////////////////////////////////////////////////////////////////
export const updateTaskProgress = async (uid: string, task_id: any, progress: any): Promise<any> => {
  const response = await axios.put(`/plan/updateTaskProgress/${uid}/${task_id}`, progress)
  return response.data.result
}

export const userAddBook = async (uid: string, book: BookWithStatistics): Promise<any> => {
  console.log('userAddBook', uid, uid, book.folderName)
  const response = await axios.post(`/user/addUserBook/${uid}/${book.folderName}`)
  const { data } = response
  console.log('userAddBook response: ', data.result)
  return { ...book, ...data.result }
}

export const updateUserBook = async (uid: string, bid: string, stats: any): Promise<any> => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.put(`/user/updateUserBook/${uid}/${bid}`, stats)
    const { data } = response
    return { ...data.result }
  } else {
    saveUserBookUpdatesToCache(uid, bid, stats)
    const data = await getCachedData('getUserBooks' + uid + '.json')
    for (let i = 0; i < data.length; i++) {
      if (data[i].folderName === bid) {
        for (const p in stats) {
          data[i][p] = stats[p]
        }
        break
      }
    }
    console.log('updateUserBook books', data)
    saveDataToCache(JSON.stringify(data), 'getUserBooks' + uid + '.json', 'updateUserBook')
    return { ...stats }
  }
}

export const userDeleteBook = async (uid: string, bid: string): Promise<any> => {
  if ((await Network.getStatus()).connected) {
    console.log('update userDeleteBook', uid, bid)
    const response = await axios.delete(`/user/deleteUserBook/${uid}/${bid}`)
    const { data } = response
    console.log('offline update userDeleteBook response: ', data.result)
    return data.result
  } else {
    console.log('update userDeleteBook !connected', uid, bid)
    saveDeletedBooksToCache(uid, bid)
    const data = await getCachedData('getUserBooks' + uid + '.json')
    console.log('userDeleteBook books', data)
    for (let i = 0; i < data.length; i++) {
      if (data[i].folderName === bid) {
        data.splice(i, 1)
        break
      }
    }
    console.log('userDeleteBook books', data)
    saveDataToCache(JSON.stringify(data), 'getUserBooks' + uid + '.json', 'userDeleteBook')
    return { uid, bid }
  }
}

export const getUserBooks = async (uid: string): Promise<BookWithStatistics[]> => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/user/getUserBooks/${uid}`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result), 'getUserBooks' + uid + '.json', 'getUserBooks')
    return data.result
  } else {
    const data = await getCachedData('getUserBooks' + uid + '.json')
    return data as BookWithStatistics[]
  }
}

export const getUserData = async (uid: string) => {
  if ((await Network.getStatus()).connected) {
    checkForCachedUpdates()
    const response = await axios.get(`/user/getUserData/${uid}`)
    const { data } = response
    // console.log('getUserData response: ', data.result, JSON.stringify(data.result))
    saveDataToCache(JSON.stringify(data.result), 'getUserData' + uid + '.json', 'getUserData')
    return data.result as User
  } else {
    const data = await getCachedData('getUserData' + uid + '.json')
    console.log('getUserData cached data: ', data)
    return data as User
  }
}

export const updateUserData = async (uid: string, stats: any) => {
  delete stats.isAnonymous
  console.log('updateUserData ax', uid, JSON.stringify(stats))
  const response = await axios.put(`/user/updateUserData/${uid}`, stats)
  const { data } = response
  console.log('updateUserData response: ', JSON.stringify(data.result))
  return data.result as User
}

export const updateUserSubscription = async (uid: string, stats: any) => {
  console.log('updateUserSubscription ax', uid, JSON.stringify(stats.subscription))
  const response = await axios.put(`/user/updateSubscription/${uid}`, stats.subscription)
  const { data } = response
  console.log('updateUserData response: ', JSON.stringify(data.result))
  return data.result as User
}

export const getTasksProgress = async (uid: string): Promise<any> => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/plan/getTasksProgress/${uid}`)
    saveDataToCache(JSON.stringify(response.data.result), 'getTasksProgress' + uid + '.json', 'getTasksProgress')
    return response.data.result
  } else {
    const data = await getCachedData('getTasksProgress' + uid + '.json')
    return data
  }
}

export const getBookWihID = async (bid: string): Promise<Book> => {
  if ((await Network.getStatus()).connected) {
    console.log('BOOK WITH ID', bid)
    const response = await axios.get(`/book/${bid}`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result), 'book' + bid + '.json', 'getBookWihID')
    return data.result
  } else {
    const data = await getCachedData('book' + bid + '.json')
    return data as Book
  }
}

export const highlightedBooks = async () => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/book/get/highlightedBooks`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result), 'highlightedBooks.json', 'highlightedBooks')
    return data.result as Book[]
  } else {
    const data = await getCachedData('highlightedBooks.json')
    return data as Book[]
  }
}

export const freeBooks = async () => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/book/get/freeBooks`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result), 'freeBooks.json', 'freeBooks')
    return data.result as Book[]
  } else {
    const data = await getCachedData('freeBooks.json')
    return data as Book[]
  }
}

export const favoriteLevelBooks = async (uid: string) => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/book/getFavoriteLevelBooks/${uid}`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result), 'favoriteLevelBooks.json', 'favoriteLevelBooks')
    return data.result as Book[]
  } else {
    const data = await getCachedData('favoriteLevelBooks.json')
    return data as Book[]
  }
}

export const bookOfTheWeek = async (uid: string) => {
  if ((await Network.getStatus()).connected) {
    const response = await axios.get(`/book/getBookOfTheWeek/${uid}`)
    const { data } = response
    saveDataToCache(JSON.stringify(data.result[0]), 'bookOfTheWeek.json', 'bookOfTheWeek')
    return data.result[0] as Book
  } else {
    const data = await getCachedData('bookOfTheWeek.json')
    return data as Book
  }
}

export const getAllBooks = async (filter?: string): Promise<Book[]> => {
  try {
    const networkStatus = await Network.getStatus()
    if (networkStatus.connected) {
      const response = await axios.get(`/book/get/allBooks${filter ? filter : ''}`)
      const { data } = response
      saveDataToCache(JSON.stringify(data.result), 'allBooks.json', 'getAllBooks')
      return data.result as Book[]
    } else {
      const data = await getCachedData('allBooks.json')
      return data as Book[]
    }
  } catch (error) {
    console.error('Error fetching books:', error)
    return []
  }
}

type SearchProps = {
  text: string
  content: boolean
}
export const search = async ({ text, content }: SearchProps): Promise<Book[]> => {
  const response = await axios.post(`/book/search`, { text })
  const { data } = response
  return data.result as Book[]
}
