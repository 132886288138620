import { forwardRef, ForwardedRef, FC } from 'react'
import { IonSpinner, IonText } from '@ionic/react'
import { VirtuosoGrid } from 'react-virtuoso'
import { Book } from '../../types/book'
import { getDownloadUrl } from '../../utils/getDownloadUrl'
import useModalState from '../../hooks/useState'
import Thumbnail from './Thumbnail'

// Otherwise the grid will remount with each render due to new component instances.
const gridComponents = {
  List: forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ style, children, ...props }, ref: ForwardedRef<HTMLDivElement>) => (
      <div
        ref={ref}
        {...props}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          ...style
        }}
      >
        {children}
      </div>
    )
  ),
  Item: forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ children, style, ...props }, ref: ForwardedRef<HTMLDivElement>) => (
      <div
        ref={ref}
        {...props}
        style={{
          padding: '0.5rem',
          display: 'flex',
          flex: 'none',
          alignContent: 'stretch',
          ...style
        }}
      >
        {children}
      </div>
    )
  )
}

const ItemWrapper = ({ children, ...props }: { children: React.ReactNode }) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flex: 1,
      textAlign: 'center',
      padding: '1rem 1rem',
      whiteSpace: 'nowrap'
    }}
  >
    {children}
  </div>
)

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-full gap-x-4">
      <IonText className="text-2xl text-tertiary-main">يرجى الإنتظار</IonText>
      <IonSpinner color={'tertiary'} />
    </div>
  )
}

interface BooksGridProps {
  books: Book[]
  query?: string
  height?: number
  isLoading?: boolean
  children?: React.ReactNode
}
const BooksGrid: FC<BooksGridProps> = ({ books, height, isLoading }) => {
  const { dispatch } = useModalState()

  if (!books || isLoading) return <Loader />

  return (
    <VirtuosoGrid
      //ion-content-scroll-host
      // className=" shadow-inner "
      style={{ height: height ? height : '100%' }}
      className="ion-content-scroll-host"
      totalCount={books.length}
      components={gridComponents}
      itemContent={(index) => (
        <ItemWrapper>
          <Thumbnail
            bid={books[index].folderName}
            downloadUrl={getDownloadUrl(books[index].folderName, books[index].level, 'cover.jpg')}
            onClick={() => {
              dispatch({ type: 'SHOW', payload: books[index].folderName })
            }}
          />
        </ItemWrapper>
      )}
    />
  )
}

export default BooksGrid
