import { FC } from 'react'
import { IonIcon, IonButton, IonSpinner } from '@ionic/react'
// hooks
import { useUserEditBookStats } from '../../hooks/useUserQueries'

interface Props {
  uid: string
  bookId: string
  isFavorite: boolean
  slot?: string
}

const AddFavoriteButton: FC<Props> = ({ uid, bookId, isFavorite, slot }) => {
  const { mutate, isError, isLoading } = useUserEditBookStats(uid, bookId)

  if (isError) console.log('Error in Add to Favorite', isError)

  return (
    <IonButton
      slot={slot}
      onClick={(e: any) => {
        e.stopPropagation()
        mutate({ isFavorite: !isFavorite })
      }}
      color={isFavorite ? 'danger' : 'primary'}
      fill="clear"
      size="small"
    >
      {isLoading ? (
        <IonSpinner name="dots" />
      ) : (
        <IonIcon
          aria-hidden="true"
          className="h-6 w-6"
          icon={isFavorite ? '/assets/icon/ic_like_f.svg' : '/assets/icon/ic_like_o.svg'}
        />
      )}
    </IonButton>
  )
}

export default AddFavoriteButton
