// import { arrowBack } from 'ionicons/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IonContent, IonPage } from '@ionic/react'
import { Pagination, EffectCreative } from 'swiper/modules'
// slides
import { IntroSlide1, IntroSlide2, IntroSlide3, IntroSlide4 } from '../../components'
// styles
import './index.css'

const Introduction: React.FC = () => {
  return (
    <IonPage>
      {/* <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="end">
            <IonBackButton icon={arrowBack} defaultHref="/login" text="تخطي" />
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen>
        <Swiper
          pagination={{ clickable: true, type: 'bullets', bulletClass: 'swiper-pagination-bullet custombulletIntro' }}
          className="h-full"
          color="inherit"
          // style={{ backgroundColor: 'red', background: '#37469B' }}
          dir="rtl"
          direction="horizontal"
          modules={[Pagination, EffectCreative]}
          // effect={'creative'}
          creativeEffect={{
            next: {
              shadow: true,
              translate: ['-20%', 0, -1]
            },
            prev: {
              translate: ['100%', 0, 0]
            }
          }}
        >
          <SwiperSlide>
            <IntroSlide1 />
          </SwiperSlide>
          <SwiperSlide>
            <IntroSlide2 />
          </SwiperSlide>
          <SwiperSlide>
            <IntroSlide3 />
          </SwiperSlide>
          <SwiperSlide>
            <IntroSlide4 />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  )
}

export default Introduction
