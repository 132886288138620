import { FC, useRef } from 'react'
import { isPlatform } from '@ionic/react'
import {
  IonModal,
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
  useIonToast,
  IonGrid,
  IonText,
  IonInput,
  IonIcon
} from '@ionic/react'
// icons
import { closeOutline } from 'ionicons/icons'
// forms
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// firebase hooks
import { Auth } from '../../firebase/config'
import { Errors } from '../../firebase/error-codes'
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
import './ForgotPassModel.css'

interface ForgotPassModelProps {
  femail: string
}

const ForgotPasswordModel: FC = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(Auth)

  const modal = useRef<HTMLIonModalElement>(null)

  const [present] = useIonToast()

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors }
  } = useForm<ForgotPassModelProps>({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        femail: Yup.string().email('البريد الإلكتروني غير صحيح').required('البريد الإلكتروني مطلوب')
      })
    )
  })

  const dismiss = () => {
    modal.current?.dismiss()
  }

  const onSubmit = async (data: any) => {
    trigger('femail')
    await sendPasswordResetEmail(data.femail).then(() => {
      present({
        message: 'تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني',
        duration: 4000,
        color: 'success'
      })
      dismiss()
    })
  }

  if (error?.message) {
    console.log('Error:', error.message)
    const errorMessage = Object.keys(Errors).find((e) => error.message.includes(e))
    const message = errorMessage ? Errors[errorMessage] : 'حدث خطأ ما'
    present({
      message,
      duration: 4000,
      color: 'danger'
    })
  }

  return (
    <IonModal
      id="example-modal"
      ref={modal}
      trigger="open-forgot-password-dialog"
      className="max-h-[400px] w-[400px] h-[400px] max-w-[90%] rounded-lg shadow-xl overflow-hidden m-auto relative forgotPassModel"
      onDidDismiss={dismiss}
    >
      <IonGrid className="p-4 space-y-2">
        <IonButton color={'medium'} fill={'outline'} onClick={() => modal.current?.dismiss()}>
          <IonIcon size="large" icon={closeOutline} slot="icon-only" />
        </IonButton>
        <IonRow>
          <IonText color={'tertiary'} className="text-xl p-1">
            هل نسيت كلمة المرور؟
          </IonText>
        </IonRow>
        <IonRow>
          <IonText color={'dark'}>
            <p>
              يرجى إدخال عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل لك عبر البريد الإلكتروني رابطًا لإعادة تعيين كلمة
              السر الخاصة بك.
            </p>
          </IonText>
        </IonRow>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonRow className="flex-col">
            <IonCol size="12" sizeMd="12" sizeLg="12">
              <IonInput
                onIonBlur={() => trigger('femail')}
                aria-label="الإيميل"
                placeholder="البريد الإلكتروني"
                type="email"
                autocomplete="on"
                shape="round"
                fill={isPlatform('android') ? 'outline' : 'solid'}
                clearInput
                className="ion-padding-start foucs:outline-none text-black focus:ring-2 focus:ring-tertiary bg-light-main rounded-3xl border-none"
                {...register('femail')}
              />
              {errors.femail && <IonText className="text-red-500">*&nbsp;{errors.femail?.message}</IonText>}
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-top">
            <IonButton
              type="submit"
              expand="full"
              shape="round"
              // disabled={!isValid}
              className="text-white w-full text-lg ion-padding"
            >
              {sending ? <IonSpinner name="lines" /> : <span>إعادة تعيين كلمة المرور</span>}
            </IonButton>
          </IonRow>
        </form>
      </IonGrid>
    </IonModal>
  )
}

export default ForgotPasswordModel
