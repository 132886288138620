import { useContext, useState, createContext } from 'react'

interface ImageContextProps {
  image: Record<string, string>
  setImage: (bid: string, image: string) => void
  imgLoading: Record<string, boolean>
  setImgLoading: (bid: string, imgLoading: boolean) => void
}

const ImageContext = createContext<ImageContextProps | undefined>(undefined)

export const useImage = () => {
  const context = useContext(ImageContext)
  if (context === undefined) {
    throw new Error('useImage must be used within a ImageProvider')
  }
  return context
}

export const ImageProvider = ({ children }: { children: React.ReactNode }) => {
  const [image, setImage] = useState<Record<string, string>>({})
  const [imgLoading, setImgLoading] = useState<Record<string, boolean>>({})

  const contextValue: ImageContextProps = {
    image,
    setImage: (bid, newImage) => {
      setImage((prev) => ({ ...prev, [bid]: newImage }))
    },
    imgLoading,
    setImgLoading: (bid, newImgLoading) => {
      setImgLoading((prev) => ({ ...prev, [bid]: newImgLoading }))
    }
  }

  return <ImageContext.Provider value={contextValue}>{children}</ImageContext.Provider>
}
