import { FC } from 'react'
import { IonLabel, IonText } from '@ionic/react'
// hooks
import { useAuth } from '../../contexts/AuthContext'

const Email: FC = () => {
  const { user } = useAuth()

  return (
    <IonLabel>
      <IonText className="text-lg font-bold text-secondary-main block w-full">
        البريد الإلكتروني:{' '}
        <span className="block sm:inline text-lg font-thin text-black w-full px-1 text-left">{user?.email}</span>
      </IonText>
    </IonLabel>
  )
}

export default Email
