import { FC, useState, useEffect, useRef } from 'react'
import {
  IonCard,
  IonSkeletonText,
  IonicSlides,
  IonThumbnail,
  IonCardContent,
  IonText,
  IonProgressBar,
  IonCardHeader,
  IonItem,
  IonImg,
  IonLabel,
  IonList,
  IonButton
} from '@ionic/react'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
// components
import { Thumbnail } from '../../components'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useGetUsersBooks } from '../../hooks/useUserQueries'
import { formatLevel, fTime } from '../../utils/format'
import useModalState from '../../hooks/useState'
import { getDownloadUrl } from '../../utils/getDownloadUrl'

import './index.css'

const ContinueReading: FC = () => {
  const { user } = useAuth()
  const { data, isLoading } = useGetUsersBooks(user?.uid)
  const { dispatch } = useModalState()

  const [slidesPerView, setSlidesPerView] = useState(1)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const calculateSlidesPerView = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth
      const slideWidth = 300 // Adjust based on your slide width
      const newSlidesPerView = Math.floor(containerWidth / slideWidth)
      setSlidesPerView(newSlidesPerView > 0 ? newSlidesPerView : 1)
    }
  }

  useEffect(() => {
    calculateSlidesPerView() // Initial calculation
    window.addEventListener('resize', calculateSlidesPerView)
    return () => {
      window.removeEventListener('resize', calculateSlidesPerView)
    }
  }, [data])

  const renderCard = () => {
    if (isLoading)
      return (
        <div className="flex flex-row gap-2 my-4">
          {Array.from({ length: 2 }).map((_, i) => (
            <IonCard
              key={i}
              className="rounded-xl flex flex-col justify-between items-center py-6 gap-y-4 w-full max-w-xl"
            >
              <div key={i} className="flex flex-col justify-between items-center w-full">
                <IonThumbnail className="w-[140px] h-[225px] sm:w-[200px] sm:h-[300px]">
                  <IonSkeletonText animated />
                </IonThumbnail>
                <IonSkeletonText animated className="w-3/5 mx-auto py-4" />
                <IonSkeletonText animated className="w-1/2 p-2 mr-2 text-right self-start" />
                <IonSkeletonText animated className="w-1/2 p-2 mr-2 text-right self-start" />
                <IonSkeletonText animated className="w-11/12 mx-auto text-right self-start" />
              </div>
            </IonCard>
          ))}
        </div>
      )
    else if (data?.length === 0)
      return (
        <>
          <h3>إبدأ القراءة</h3>
          <IonCard className="w-full h-full rounded-lg shadow-xl mx-auto my-8">
            <IonCardContent className="flex items-center justify-between">
              <div className="flex flex-col gap-4">
                <IonText className="text-lg">
                  إبدأ القراءة الآن لتتمكن من متابعة الكتب التي تقرأها والعودة إليها في أي وقت
                </IonText>
                <IonButton routerLink="/d/search" color="primary" className="w-1/2 mt-4 self-center">
                  ابدأ القراءة
                </IonButton>
              </div>
              <IonImg
                src="/assets/illustrations/library.svg"
                className="w-[250px] md:w-[300px] landscape:max-w-[100px] xl:landscape:max-w-[250px]"
              />
            </IonCardContent>
          </IonCard>
        </>
      )
    else
      return (
        <div className="p-4 rounded-xl bg-primary-lighter">
          <h3>أكمل القراءة</h3>
          <Swiper
            dir="rtl"
            slidesPerView={slidesPerView}
            spaceBetween={10}
            pagination={{
              clickable: true,
              type: 'bullets',
              horizontalClass: 'custompag',
              bulletClass: 'swiper-pagination-bullet custombullet'
            }}
            // autoplay={{
            //   delay: 5000,
            //   disableOnInteraction: false
            // }}
            modules={[IonicSlides, Pagination, Autoplay]}
            className="p-8"
          >
            {data
              ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
              .slice(0, 4)
              .map((book, idx) => (
                <SwiperSlide key={`${book.folderName}-${idx}`}>
                  <IonCard
                    onClick={() => dispatch({ type: 'SHOW', payload: book.folderName })}
                    className=" p-2 md:p-0 flex md:flex-col flex-row max-w-full w-lvw rounded-lg overflow-hidden border border-gray-200 shadow-md"
                  >
                    {/* Image Section */}
                    <IonCardHeader className="md:px-4 w-1/2 md:w-full mx-auto ion-no-padding">
                      <Thumbnail
                        bid={book.folderName}
                        downloadUrl={getDownloadUrl(book.folderName, book.level, 'cover.jpg')}
                        // classname="w-full sm:w-full min-w-[100px] p-0 h-[200px] sm:h-[300px] "
                      />
                    </IonCardHeader>
                    {/* Content Section */}
                    <IonCardContent className="w-1/2 md:w-full ion-no-padding">
                      <IonList>
                        {/* Title */}
                        <IonText className="text-xl font-semibold text-black line-clamp-1 w-11/12">
                          {book.title}
                        </IonText>
                        {/* Level */}
                        <IonItem>
                          <IonImg
                            slot="start"
                            src="/assets/icon/ic_book_level_green.svg"
                            alt="level"
                            className="w-8 h-8 ml-2"
                          />
                          <IonLabel>
                            <IonText className="text-gray-400 font-bold">المستوى: </IonText>
                            <IonText>{formatLevel(book.level)}</IonText>
                          </IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonImg
                            slot="start"
                            src="/assets/icon/ic_reading_duration_green.svg"
                            alt="level"
                            className="w-8 h-8 ml-2"
                          />
                          <IonLabel>
                            <IonText className="text-gray-400 font-bold">المدة: </IonText>
                            <IonText>{fTime(book.duration ? book.duration : 0)}</IonText>
                          </IonLabel>
                        </IonItem>
                        {/* Progress */}
                        <IonItem>
                          <IonImg
                            slot="start"
                            src="/assets/icon/ic_book_green.svg"
                            alt="level"
                            className="w-8 h-8 ml-2"
                          />
                          <IonProgressBar value={book.pageReached / book.pages} className="w-full" />
                          <IonText slot="end">{`${Math.round((book.pageReached / book.pages) * 100)}%`}</IonText>
                        </IonItem>
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )
  }

  return <div ref={containerRef}>{renderCard()}</div>
}

export default ContinueReading
