import React from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'

interface PrivateRouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  path: string
  exact?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, exact = false, ...rest }) => {
  const { user } = useAuth()

  return (
    <Route {...rest} exact={exact} render={(props) => (user ? <Component {...props} /> : <Redirect to="/login" />)} />
  )
}

export default PrivateRoute
