import { useMediaQuery } from 'react-responsive'

// -------------------------------------------------

type ReturnType = boolean

type Query = 'up' | 'down' | 'only' | 'between'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
}

export function useResponsive(query: Query, breakpoint: Breakpoint): ReturnType {
  const queryType = {
    up: useMediaQuery({ minWidth: BREAKPOINTS[breakpoint] }),
    down: useMediaQuery({ maxWidth: BREAKPOINTS[breakpoint] }),
    only: useMediaQuery({ minWidth: BREAKPOINTS[breakpoint], maxWidth: BREAKPOINTS[breakpoint] }),
    between: useMediaQuery({ minWidth: BREAKPOINTS[breakpoint], maxWidth: BREAKPOINTS[breakpoint] })
  }

  return queryType[query]
}
