import { FC } from 'react'
import { IonButton, IonIcon, IonLabel, useIonToast } from '@ionic/react'
// import { useHistory } from 'react-router'
// firebase hooks
import { Auth } from '../../firebase/config'
import { useSignOut } from 'react-firebase-hooks/auth'
import { useQueryClient } from '@tanstack/react-query'

const SignOutButton: FC = () => {
  const queryClient = useQueryClient()
  const [signOut, loading, error] = useSignOut(Auth)

  // const history = useHistory()
  const [presentToast] = useIonToast()

  const handleSignOut = async () => {
    // queryClient.cancelQueries({ queryKey: ['user'] })
    queryClient.removeQueries(['user'])
    await signOut()

    // history.replace('/login')
  }

  if (error?.message) {
    console.error(error)
    presentToast({
      message: error.message,
      duration: 3000,
      position: 'top',
      color: 'danger',
      cssClass: 'text-center'
    })
  }

  return (
    <>
      <IonLabel>تسجيل الخروج</IonLabel>
      <IonButton color={'light'} fill="clear" onClick={handleSignOut} disabled={loading} slot="end">
        <IonIcon icon="/assets/icon/ic_exit.svg" className="w-6 h-6" />
      </IonButton>
    </>
  )
}

export default SignOutButton
