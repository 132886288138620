import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { getTasksProgress, updateTaskProgress } from '../api'
import { Plan } from '../types/plan'

export const useUserPlans = (uid: string) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['plans', uid],
    queryFn: async () => {
      const plans = await getTasksProgress(uid)
      queryClient.setQueryData(['plans', uid], plans)
      return plans as Plan[]
    },
    enabled: !!uid
  })
}

interface UpdateTasks {
  task_id: number
  data: any
}

export const useUpdateTaskProgress = (uid: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['plans', uid],
    mutationFn: async ({ task_id, data }: UpdateTasks) => {
      // Optimistically update the cache with the updated task and new data
      queryClient.setQueryData(['plans', uid], (prev: Plan[] | undefined) =>
        prev
          ? prev.map((plan) => ({
              ...plan,
              tasks: plan.tasks.map((task) => {
                if (task.task_id === task_id) {
                  return { ...task, ...data }
                }
                return task
              })
            }))
          : []
      )
      const plans = await updateTaskProgress(uid, task_id, data)
      return plans as Plan[]
    },
    onSuccess: () => {
      // Invalidate and refetch the plans query to ensure data consistency
      queryClient.invalidateQueries(['plans', uid])
    }
  })
}
