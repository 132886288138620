import { FC, useEffect, useState } from 'react'
import { NotFound } from '../../components'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useGetUsersBooks, useUserEditBookStats } from '../../hooks/useUserQueries'
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonList,
  IonIcon,
  IonLabel,
  IonButton,
  IonSpinner
} from '@ionic/react'

interface bookmarksArray {
  bid: string
  page: number
  title: string
  bookmarks: string
  readCount: number
}

const UserBookmarks: FC = () => {
  const [bookmarks, setBookmarks] = useState<bookmarksArray[]>([])
  // const [editedBookmarks, setEditedBookmarks] = useState<string>('')
  const [bookId, setBookId] = useState<string>('')
  const { user } = useAuth()
  const { data: userBooks, isLoading } = useGetUsersBooks(user.uid)
  const { mutate, isError } = useUserEditBookStats(user.uid, bookId)

  useEffect(() => {
    const getBookmarks = () => {
      if (!userBooks) return
      const bookmarks = userBooks.reduce((acc, book) => {
        const { bookmarks, downloaded } = book
        const bookMarksWithBookId = [] as any
        if (!downloaded) return acc
        for (let i = 0; i < bookmarks.length; i++) {
          if (bookmarks[i] === '1') {
            bookMarksWithBookId.push({ bid: book.folderName, page: i + 1, title: book.title, bookmarks })
          }
        }
        if (bookmarks.length > 0) {
          return [...acc, ...bookMarksWithBookId]
        }
        return acc
      }, [] as any)
      bookmarks.sort((a: any, b: any) => a.title.localeCompare(b.title))
      // bookmarks.sort((a: any, b: any) => parseFloat(a.page) - parseFloat(b.page))
      return bookmarks
    }
    setBookmarks(getBookmarks())
  }, [userBooks])

  const handleDelete = (bid: string, page: number, bookmarks: string) => {
    setBookId(bid)
    const newBookmarks = bookmarks.substring(0, page - 1) + '0' + bookmarks.substring(page)
    // setEditedBookmarks(newBookmarks)
    mutate({ bookmarks: newBookmarks })
  }

  if (isError) {
    console.log('Error in UserBookmarks', isError)
  }

  return bookmarks?.length > 0 ? (
    <IonCard className="overflow-auto rounded-xl max-w-4xl mx-auto">
      <IonCardTitle className="font-bold pt-4 pr-4">مرجعيات الصفحات</IonCardTitle>
      <IonCardContent>
        <IonList>
          {bookmarks.map(({ bid, page, bookmarks, title, readCount }, idx) => (
            <IonItem key={`${bid}__${page}__${idx}`} className="flex flex-row items-center justify-between ">
              <IonItem className="w-full" routerLink={`/story/${bid}/${page}/${readCount}/false`}>
                <IonIcon icon="/assets/icon/ic_nav_notebook_f.svg" slot="start" />
                <IonLabel className="ion-text-wrap">
                  <span className="font-29LTKaff">{title}</span>
                </IonLabel>
                <IonLabel slot="end">صفحة {page}</IonLabel>
              </IonItem>
              <IonButton
                slot="end"
                color="danger"
                fill="clear"
                size="small"
                onClick={() => handleDelete(bid, page, bookmarks)}
              >
                {/* {isLoading ? (
                  <IonSpinner name="lines-sharp-small" />
                ) : ( */}
                <IonIcon slot="icon-only" aria-hidden="true" icon={'/assets/icon/ic_delete.svg'} />
                {/* )} */}
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : isLoading ? (
    <IonSpinner name="lines-sharp" className="w-full mx-auto" />
  ) : (
    <NotFound title="مفكّرتك خالية" subtitle="احفظ مرجعياتك في المفكّرة" img="/assets/illustrations/notebook.png" />
  )
}

export default UserBookmarks
