import { FC, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import {
  useIonRouter,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButton
} from '@ionic/react'
import { BookOfWeek, ContinueReading, FavoriteBooks, FreeBooks, SignInModal } from '../../components'
import { personCircleOutline } from 'ionicons/icons'
//
import { useAuth } from '../../contexts/AuthContext'
import { useResponsive } from '../../hooks/use-responsive'
//
import { Auth } from '../../firebase/config'

/**
 * Renders the Library page component - the main page of the app.
 *
 * @return {ReactElement} The rendered Library page component.
 */
const Library: FC = () => {
  const { user } = useAuth()

  // const router = useIonRouter()
  const history = useHistory()

  const isDesktop = useResponsive('up', 'md')

  const { currentUser } = Auth

  const [open, setOpen] = useState<boolean>(false)

  const handleClickProfile = () => {
    if (currentUser?.isAnonymous) {
      console.log('User is anonymous')
      setOpen(true)
      return
    }
    // router.push('/profile')
    history.push('/profile')
  }

  if (!user) {
    return <Redirect to="/login" />
  }

  console.log('isDesktop:', isDesktop)

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar color="primary">
          <IonImg src="/assets/full_logo.svg" alt="stories app logo" className="w-[100px] h-[30px]" />
          <IonButtons slot="end">
            {/* <SignOutButton /> */}
            <IonButton
              fill="clear"
              onClick={handleClickProfile}
              // routerLink="/profile"
            >
              <IonIcon icon={personCircleOutline} size="large" className="navy" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding bg-img">
        <div className="max-w-prose">
          <BookOfWeek />
        </div>
        <ContinueReading />
        <FreeBooks />
        <FavoriteBooks />
        <SignInModal
          isOpen={open}
          message="تريد إنشاء حساب والاشتراك لتتمكن من قراءة القصص وإضافتها إلى مكتبتك؟"
          onDismiss={() => {
            console.log('Dismissed')
            setOpen(false)
          }}
        />
      </IonContent>
    </IonPage>
  )
}

export default Library
