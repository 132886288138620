import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Preferences } from '@capacitor/preferences'
import { updateUserBook, userDeleteBook } from '../../api'

const fileExists = async (path: string): Promise<boolean> => {
  try {
    await Filesystem.stat({
      path: path,
      directory: Directory.Data
    })
    return true
  } catch (e) {
    console.log('checkFileExists:', path, false, e)
    return false
  }
}

export const saveDataToCache = async (data: string, fileName: string, tag: string) => {
  try {
    await Filesystem.writeFile({
      path: fileName,
      data: data,
      encoding: Encoding.UTF8,
      directory: Directory.Data
    })
  } catch (error) {
    console.log('error in saveDataToCache:', error)
  }
}

export const getCachedData = async (fileName: string) => {
  if (await fileExists(fileName)) {
    try {
      const contents = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      })
      console.log('getCachedData: ', fileName, contents)
      return JSON.parse(contents.data as string)
    } catch (error) {
      console.error('getCachedData.Error', error)
      return {}
    }
  } else {
    return {}
  }
}

const clearCachedData = async () => {
  await Preferences.remove({ key: 'HasCachedData' })
  await Filesystem.deleteFile({
    path: 'cachedUpdates.json',
    directory: Directory.Data
  }).catch((error) => {
    console.error('Deletion error: ', error)
  })
}

export const clearUserCachedData = async (uid: string) => {
  await clearCachedData()
  const filesToDelete = [
    'getUserData' + uid + '.json',
    'getUserBooks' + uid + '.json',
    'getTasksProgress' + uid + '.json'
  ]
  for (let i = 0; i < filesToDelete.length; i++) {
    if (await fileExists(filesToDelete[i])) {
      try {
        await Filesystem.deleteFile({
          path: filesToDelete[i],
          directory: Directory.Data
        }).catch((error) => {
          console.error('Deletion error: ', error)
        })
      } catch (error) {
        console.error('Deletion error: ', error)
      }
    }
  }
}

export const checkForCachedUpdates = async () => {
  if ((await Preferences.keys()).keys.includes('HasCachedData')) {
    const cachedData = await getCachedData('cachedUpdates.json')
    if (cachedData) {
      console.log('checkForCachedUpdates, cachedData:', cachedData)
      const userBooks = cachedData['userBooks'] || []
      const deletedBooks = cachedData['deletedBooks'] || []
      let n = userBooks.length + deletedBooks.length
      try {
        for (let i = 0; i < userBooks.length; i++) {
          const uid = userBooks[i]['uid']
          const bid = userBooks[i]['bid']
          const bidObj = { ...userBooks[i] }
          delete bidObj['uid']
          delete bidObj['bid']
          await updateUserBook(uid, bid, bidObj)
          n--
        }
        for (let i = 0; i < deletedBooks.length; i++) {
          const book = deletedBooks[i]
          const uid = book['uid']
          const bid = book['bid']
          await userDeleteBook(uid, bid)
          n--
        }
        if (n === 0) await clearCachedData()
      } catch (error) {
        console.error('checkForCachedUpdates clearCachedData error:', error)
      }
    }
  }
}

export const saveUserBookUpdatesToCache = async (uid: string, bid: string, stats: any) => {
  Preferences.set({ key: 'HasCachedData', value: 'true' })
  const cachedData = await getCachedData('cachedUpdates.json')
  console.log('saveUserBookUpdatesToCache', cachedData)
  if (cachedData) {
    const userBooks = cachedData['userBooks'] || []
    console.log('saveUserBookUpdatesToCache userBooks', userBooks)
    const userBook = userBooks.find((book: any) => book.bid === bid)
    if (userBook) {
      for (const p in stats) {
        userBook[p] = stats[p]
      }
    } else {
      userBooks.push({ bid: bid, uid: uid, ...stats })
    }
    cachedData['userBooks'] = userBooks
    console.log('saveUserBookUpdatesToCache 2:', cachedData)
  }
  await saveDataToCache(JSON.stringify(cachedData), 'cachedUpdates.json', 'saveUserBookUpdatesToCache')
}

export const saveDeletedBooksToCache = async (uid: string, bid: string) => {
  Preferences.set({ key: 'HasCachedData', value: 'true' })
  const cachedData = await getCachedData('cachedUpdates.json')
  console.log('saveDeletedBooksToCache', cachedData)
  if (cachedData) {
    const deletedBooks = cachedData['deletedBooks'] || []
    deletedBooks.push({ uid: uid, bid: bid })
    cachedData['deletedBooks'] = deletedBooks
    console.log('saveUpdatesToCache:', cachedData)
  }
  await saveDataToCache(JSON.stringify(cachedData), 'cachedUpdates.json', 'saveDeletedBooksToCache')
  const data = await getCachedData('cachedUpdates.json')
  console.log('saveDeletedBooksToCache in cachedUpdates.json', data)
}
