import { FC, useEffect, useState } from 'react'
import { IonAvatar, IonImg, IonButton, IonIcon, isPlatform, useIonToast } from '@ionic/react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { camera } from 'ionicons/icons'
// firebase
import { storage } from '../../firebase/config'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
// hooks
import { useAuth } from '../../contexts/AuthContext'
import { useEditUserData } from '../../hooks/useUser'
import { useNetwork } from '../../contexts/NetworkContext'

const Avatar: FC = () => {
  const { user } = useAuth()
  const { connected } = useNetwork()
  const { mutate: editAvatar } = useEditUserData(user.uid)

  const [avatar, setAvatar] = useState<string | undefined>('/assets/icon/ic_avatar.svg')

  const [present] = useIonToast()

  useEffect(() => {
    if (user.avatar) {
      setAvatar(user.avatar)
    }
  }, [user])

  useEffect(() => {
    if (!connected) {
      present({
        message: 'لا يوجد اتصال بالانترنت',
        duration: 2000,
        color: 'danger',
        position: 'bottom',
        cssClass: 'text-center'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected])

  //? if you want to use the camera, uncomment the following code
  // const takePhoto = async () => {
  //   try {
  //     const cameraPhoto = await Camera.getPhoto({
  //       resultType: CameraResultType.Uri,
  //       source: CameraSource.Camera,
  //       quality: 90
  //     })

  //     if (cameraPhoto && cameraPhoto.webPath) {
  //       setAvatar(cameraPhoto.webPath)
  //       handleAvatarChange(cameraPhoto.webPath)
  //     }
  //   } catch (error) {
  //     console.error('Error taking photo:', error)
  //   }
  // }

  const selectFromFiles = async () => {
    try {
      const galleryPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        quality: 90
      })

      if (galleryPhoto && galleryPhoto.webPath) {
        setAvatar(galleryPhoto.webPath)
        handleAvatarChange(galleryPhoto.webPath)
      }
    } catch (error) {
      console.error('Error selecting from files:', error)
    }
  }

  const compressImage = async (imageUri: string): Promise<Blob> => {
    const image = new Image()
    image.src = imageUri

    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const MAX_WIDTH = 800
        const MAX_HEIGHT = 800
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }

        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx?.drawImage(image, 0, 0, width, height)

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            } else {
              throw new Error('Error compressing image')
            }
          },
          'image/jpeg',
          0.7
        ) // 0.7 is the image quality, adjust as needed
      }
    })
  }

  const uploadAvatar = async (uid: string, imageUri: string): Promise<void> => {
    try {
      const compressedImageBlob = await compressImage(imageUri)
      const imagePath = `users/${uid}/photo.jpg`

      // const uploadTask = storageRef.child(imagePath).put(compressedImageBlob)
      const uploadTask = await uploadBytes(storageRef(storage, imagePath), compressedImageBlob)

      // get download url
      const downloadURL = await getDownloadURL(uploadTask.ref)

      // update user data
      editAvatar({ avatar: downloadURL })

      // set avatar
      setAvatar(downloadURL)

      present({
        message: 'تم تغيير الصورة بنجاح',
        duration: 2000,
        color: 'success',
        position: 'bottom',
        cssClass: 'text-center'
      })
    } catch (error) {
      console.error('Error compressing image:', error)
    }
  }

  const handleAvatarChange = async (imageUri: string) => {
    // Call the function to upload the avatar to Firebase Cloud Storage
    await uploadAvatar(user.uid, imageUri)
  }

  return (
    <div className="text-center">
      <IonAvatar className="w-48 h-48 flex items-center justify-center mx-auto border-4 border-gray-300 bg-gray-100 object-contain shadow-lg relative">
        <IonImg src={avatar} />
        <IonButton
          disabled={!connected}
          className="absolute bottom-0 left-4 flex flex-row justify-center items-center"
          style={{
            '--padding-start': isPlatform('ios') ? '1rem' : '0.5rem',
            '--padding-end': isPlatform('ios') ? '1rem' : '0.5rem'
          }}
          shape="round"
          onClick={selectFromFiles}
        >
          <IonIcon icon={camera} />
        </IonButton>
      </IonAvatar>
    </div>
  )
}

export default Avatar
